import $ from "jquery";
import jwt from "jwt-decode";
import moment from "moment";
import { RedirectTo } from "../routes/path";

import { ScriptHandler } from "../script/ScriptHandler";
import {
  changeClassForCurrentFund,
  contactPageHideParentIfBioNotPresent,
  copyRightYearOldTenantReplacer,
  footerAddressReplacerOrHideInnerChild,
  learnMoreClass,
  ccTitleForTenant,
  mobileAccordionScrollTopZero,
  hideSelectFundInMobile,
  changePlusMinusIconPOC,
  renderSocialIconAsText,
} from "../tenantReplacer";
import {
  adaCheckerForLeftAndRightArrow,
  adaTenants,
  addClassToCarausalSectionChecker,
  broadcastReadMoreTextAndIconHandler,
  containerFluidFixesChecker,
  customPanelYearReplacerForReport,
  customPanelYearReplacerForSite,
  customVisitorCheckers,
  learnMoreDisplayFeature,
  learnMoreDisplayShouldFlex,
  learnMoreDisplayShouldTable,
  mobileDefaultHeaderRetroactiveChange,
  notRequiredAmountConversion,
  numberFormatStartWithZero,
  removeDropDownClass,
  showCarouselIndicatorNumber,
} from "../checkers";
import { useRef } from "react";
import { adaLibMenu, callADA, jumpToContent } from "./AdaLibMethods";
import { getCustomVisit } from "../storage";
import { fetchS3Url } from "../s3Mapping";
import { WistiaEditorIFrame } from "../wistia/WistiaEditorIFrame";

var type = window.location.pathname.includes("/reports") ? "report" : "site";
let windowInnerWidth;

export const onLoadEvent = () => {
  $(window).ready(function () {
    windowInnerWidth = window.innerWidth;
  });
  $(window).resize(function () {
    if (windowInnerWidth != window.innerWidth) {
      mobileSize();
    }
  });
};

export const mobileSize = () => {
  if (window.innerWidth < 992) {
    // Mobile View
    $(".carousel-inner").css("display", "none");

    // fixed for OV-11162 - 3rd point
    if (!hideSelectFundInMobile.includes(getTenantOrgId())) {
      $(".slideSecClass .carousel-indicators").css("display", "none");
    }

    $(".carousel-control").css("display", "none");
    //Report-Carousel Indicators Hide on mobile view bydefault
    if (!hideSelectFundInMobile.includes(getTenantOrgId())) {
      $(".slideSecClass .carousel-indicators-replica").css("display", "none");
    }

    $(".slideSecClass .list-fund-options .carousel-indicators.report").css(
      "display",
      "block"
    );
  } else {
    // Desktop View
    $(".carousel-inner").css("display", "inherit");
    //Report-Carousel Indicators Hide on mobile view bydefault
    $(".slideSecClass .carousel-indicators-replica").css("display", "inherit");
    $(".carousel-control").css("display", "inherit");
  }
};
$(document).on("keyup keypress keydown", function (event) {
  if (
    (event?.keyCode == 48 &&
      event?.which == 48 &&
      event?.shiftKey == false &&
      event?.altKey == false &&
      event?.ctrlKey == false &&
      event?.metaKey == true &&
      event?.key == 0) ||
    (event?.keyCode == 48 &&
      event?.which == 48 &&
      event?.shiftKey == false &&
      event?.altKey == false &&
      event?.ctrlKey == true &&
      event?.metaKey == false &&
      event?.key == 0)
  ) {
    // Desktop View
    $(".carousel-inner").css("display", "inherit");
    //Report-Carousel Indicators Hide on mobile view bydefault
    $(".slideSecClass .carousel-indicators-replica").css("display", "inherit");
    $(".carousel-control").css("display", "inherit");
  }
});
export const carouselMigration = () => {
  let carousels = document.querySelectorAll(".carousel");
  for (let i = 0; i < carousels.length; i++) {
    const carousel = carousels[i];
    addAttribute(carousel, "data-ride", "data-bs-ride");
    addAttribute(carousel, "data-interval", "data-bs-interval");
    const items = carousel.querySelectorAll(".carousel-inner .item");
    for (let j = 0; j < items.length; j++) {
      const item = items[j];
      item.classList.add("carousel-item");
    }
  }

  document.addEventListener("touchstart", handleTouchStart, false);
  document.addEventListener("touchmove", handleTouchMove, false);

  let xDown = null;
  let yDown = null;
  let objId = "";
  let objLength = "";
  let num = "";

  function getTouches(evt) {
    return (
      evt.touches || // browser API
      evt.originalEvent.touches
    ); // jQuery
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
    localStorage.removeItem("CarouselCount");
  }

  function handleTouchMove(evt) {
    if (evt.target.closest(".carousel-item")) {
      if (xDown || yDown) {
        objLength =
          evt.target.closest(".carousel-item").parentElement.children.length;
        objId = evt.target.closest(".carousel").id;
      }

      for (let i = 0; i < objLength; i++) {
        if (
          document
            .querySelectorAll(
              '.carousel-indicators [data-bs-target="#' + objId + '"]'
            )
            [i]?.classList?.contains("active")
        ) {
          num = i;
        }
      }
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;
    if (
      localStorage.getItem("CarouselCount") === null &&
      evt.target.closest(".carousel-item")
    ) {
      const SWIPE_THRESHOLD = 20;
      if (
        Math.abs(xDiff) > Math.abs(yDiff) &&
        Math.abs(yDiff) < SWIPE_THRESHOLD
      ) {
        /*most significant*/
        if (xDiff > 0) {
          /* right swipe */
          if (num !== objLength - 1) {
            localStorage.setItem("CarouselCount", 1);
            document
              ?.querySelector(
                '[data-bs-target="#' +
                  objId +
                  '"][data-bs-slide-to="' +
                  (Number(num) + 1) +
                  '"]'
              )
              ?.click();
            objId = "";
          }
        } else {
          /* left swipe */
          if (num !== 0) {
            localStorage.setItem("CarouselCount", 1);
            document
              .querySelector(
                '[data-bs-target="#' +
                  objId +
                  '"][data-bs-slide-to="' +
                  (Number(num) - 1) +
                  '"]'
              )
              .click();
            objId = "";
          }
        }
      }
    }

    /* reset values */
    xDown = null;
    yDown = null;
  }

  var carousel = document.getElementsByClassName("carousel");
  for (var i = 0; i < carousel.length; i++) {
    //Parent
    carousel[i].setAttribute("data-bs-keyboard", "false");
    carousel[i].removeAttribute("data-ride");
    carousel[i].removeAttribute("data-interval");
    if (carousel[i].hasAttribute("data-bs-ride") === false) {
      carousel[i].setAttribute("data-bs-ride", "carousel");
    }
    if (carousel[i].hasAttribute("data-bs-interval") === false) {
      carousel[i].setAttribute("data-bs-interval", "false");
    }
    if (carousel[i].hasAttribute("data-bs-touch") === false) {
      carousel[i].setAttribute("data-bs-touch", "false");
    }

    //Inner Item
    var items = carousel[i].querySelectorAll(".item");
    for (var j = 0; j < items.length; j++) {
      items[j].classList.remove("active");
      if (j === 0) {
        items[j].classList.add("carousel-item");
        items[j].classList.add("active");
      } else {
        items[j].classList.add("carousel-item");
      }
    }

    //Carousel-Indicators
    var indicators = carousel[i].querySelectorAll(".carousel-indicators li");

    if (
      indicators?.length < 2 &&
      carousel[i]?.querySelectorAll(".select-fund")?.length > 0
    ) {
      carousel[i].querySelectorAll(".select-fund")[0].style.display = "none";
    }

    for (var l = 0; l < indicators.length; l++) {
      if (indicators[l].hasAttribute("data-bs-target") === false) {
        indicators[l].setAttribute(
          "data-bs-target",
          indicators[l].getAttribute("data-target")
        );
      }
      if (indicators[l].hasAttribute("data-bs-slide-to") === false) {
        indicators[l].setAttribute(
          "data-bs-slide-to",
          indicators[l].getAttribute("data-slide-to")
        );
      }
      indicators[l].removeAttribute("data-target");
      indicators[l].removeAttribute("data-slide-to");
    }

    //Carousel - Control;
    var carouselControlArr = new Array(
      ".carousel-control.right",
      ".carousel-control.left"
    );
    carousel[i]
      ?.querySelector(carouselControlArr[0])
      ?.classList.add("carousel-control-next");
    carousel[i]
      ?.querySelector(carouselControlArr[1])
      ?.classList.add("carousel-control-prev");
    if (carousel[i]?.querySelectorAll(".carousel-control")?.length === 2) {
      carousel[i].querySelector(carouselControlArr[0]).removeAttribute("href");
      carousel[i].querySelector(carouselControlArr[1]).removeAttribute("href");
    }
    resetArrow(carousel[i], 0);
  }
  indicatorsInitHandler(0);
  arrowInitHandler("init");
  migrationRemovePrivateUrl();
  addTargetBlankInATag();
};

const addTargetBlankInATag = () => {
  try {
    setTimeout(() => {
      document
        .querySelectorAll(".link-embed-parent .inline-textEditor a")
        ?.forEach((ele) => {
          if (ele.getAttribute("target") !== "_blank") {
            ele.setAttribute("target", "_blank");
          }
        });
    }, 2000);
  } catch (error) {}
};

const addAttribute = (element, oldAtt, newAtt, flag, carouselId) => {
  if (element?.hasAttribute(oldAtt)) {
    const newVal = element.getAttribute(oldAtt);
    if (flag) {
      element.setAttribute(oldAtt, `#` + carouselId.id);
      element.setAttribute(newAtt, `#` + carouselId.id);
    } else {
      element.setAttribute(newAtt, newVal);
      element.removeAttribute(oldAtt);
    }
  }
};

export const broadcastMigration = () => {
  const broadcast = document.querySelector(".broadCastTemplpatesWrapper");
  addAttribute(
    broadcast?.querySelector(".broadcastBtnWrapper"),
    "data-toggle",
    "data-bs-toggle"
  );
  addAttribute(
    broadcast?.querySelector(".broadcastBtnWrapper"),
    "data-target",
    "data-bs-target"
  );
  if (
    broadcast
      ?.querySelector(".broadCastCollapsible")
      ?.classList?.contains("row")
  ) {
    broadcast?.querySelector(".broadCastCollapsible")?.classList.remove("row");
  }
  let openCloseIcon = broadcast?.querySelector(
    ".learn-more-text .close-banner"
  );
  // removing the close icon in publish time
  if (openCloseIcon) {
    let removeCloseIcon = broadcast?.querySelector(".learn-more-text");
    removeCloseIcon.firstElementChild?.setAttribute("class", "open-banner");
    removeCloseIcon.setAttribute("aria-label", "Press enter to open the alert");
    broadcast?.querySelector(".broadCastCollapsible")?.classList.remove("show");
    if (
      broadcastReadMoreTextAndIconHandler?.includes(Number(getTenantOrgId()))
    ) {
      removeCloseIcon.firstElementChild?.setAttribute(
        "class",
        "open-banner tablet-web-only"
      );
      removeCloseIcon.firstElementChild.innerHTML = "READ MORE";
      removeCloseIcon
        .querySelector("i")
        .setAttribute("class", "glyphicon glyphicon-chevron-down");
    } else if (Number(getTenantOrgId()) === 31) {
      removeCloseIcon.firstElementChild?.setAttribute(
        "class",
        "open-banner tablet-web-only"
      );
      // removeCloseIcon.firstElementChild.innerHTML = "READ MORE";
      removeCloseIcon
        .querySelector("i")
        .setAttribute("class", "glyphicon glyphicon-chevron-down");
    }
  }
  //Removing class show to keep it clase initially
  if (broadcast?.querySelector(".learn-more-text")) {
    broadcast
      ?.querySelector(".learn-more-text")
      .addEventListener("click", iconHandler);
  } else if (broadcast?.querySelector(".learn-more-text-2022")) {
    broadcast
      ?.querySelector(".learn-more-text-2022")
      .addEventListener("click", iconHandler);
  }
};

function iconHandler() {
  let classNm = this.firstElementChild.classList;
  if (
    classNm.contains("close-banner") ||
    (classNm[1] === "glyphicon-remove" && getTenantOrgId() === "31")
  ) {
    this.setAttribute("aria-label", "Press enter to open the alert");
    this.firstElementChild?.setAttribute("class", "open-banner");
    if (
      broadcastReadMoreTextAndIconHandler?.includes(Number(getTenantOrgId()))
    ) {
      this.firstElementChild?.setAttribute(
        "class",
        "open-banner tablet-web-only"
      );
      this.firstElementChild.innerHTML = "READ MORE";
      this.querySelector("i").setAttribute(
        "class",
        "glyphicon glyphicon-chevron-down"
      );
    } else if (Number(getTenantOrgId()) === 31) {
      this.firstElementChild?.setAttribute(
        "class",
        "open-banner tablet-web-only"
      );
      this.querySelector("i").setAttribute(
        "class",
        "glyphicon glyphicon-chevron-down"
      );
    }
  } else {
    this.setAttribute("aria-label", "Press enter to close the alert");
    this.firstElementChild?.setAttribute("class", "close-banner");
    if (
      broadcastReadMoreTextAndIconHandler?.includes(Number(getTenantOrgId()))
    ) {
      this.firstElementChild?.setAttribute(
        "class",
        "close-banner tablet-web-only"
      );
      this.firstElementChild.innerHTML = "CLOSE";
      this.querySelector("i").setAttribute(
        "class",
        "glyphicon glyphicon-remove"
      );
    } else if (Number(getTenantOrgId()) === 31) {
      this.firstElementChild?.setAttribute(
        "class",
        "close-banner tablet-web-only"
      );
      this.querySelector("i").setAttribute(
        "class",
        "glyphicon glyphicon-remove"
      );
    }
  }
}

export const updatePhotoGalleryCarouselTwoId = (previewOnly, customElement) => {
  let document_ = customElement ? customElement : document;
  let photoGalleryCarouselArray = document_?.querySelectorAll(
    "#photoGalleryCarousel.X2"
  );
  if (previewOnly) {
    photoGalleryCarouselArray = document_?.querySelectorAll(
      previewOnly + " #photoGalleryCarousel.X2"
    );
  }
  photoGalleryCarouselArray?.forEach((element) => {
    element.id = "photoGalleryCarouselTwo";
  });
  // For University of Delaware
  let photoGalleryCarousel2Array = document_?.querySelectorAll(
    ".VX2 #photoGalleryCarousel"
  );
  if (previewOnly) {
    photoGalleryCarousel2Array = document_?.querySelectorAll(
      previewOnly + " .VX2 #photoGalleryCarousel"
    );
  }
  photoGalleryCarousel2Array?.forEach((element) => {
    element.id = "photoGalleryCarouselTwo";
  });

  let photoGalleryCarousel3Array = document_?.querySelectorAll(
    ".X2 #photoGalleryCarousel"
  );
  if (previewOnly) {
    photoGalleryCarousel3Array = document_?.querySelectorAll(
      previewOnly + " .X2 #photoGalleryCarousel"
    );
  }
  photoGalleryCarousel3Array?.forEach((element) => {
    element.id = "photoGalleryCarouselTwo";
  });
};

export const containerFluidFixes = (orgId) => {
  if (
    containerFluidFixesChecker.includes(orgId) ||
    (orgId === 16 && document?.querySelector("body.REBRAND_2021_BODY")) ||
    (orgId === 12 && document?.querySelector(".slideSecClass .version_2_2020"))
  ) {
    var panel = document.getElementsByClassName("contentpanelname");
    for (var i = 0; i < panel.length; i++) {
      var parent = panel[i].parentElement;
      //top
      parent.classList.remove("container");
      parent.classList.add("container-fluid");
      //contentpanelname
      panel[i].classList.add("container");
      panel[i].classList.add("no-padding");
      // Specific for Stanford full width iframe and Video panel
      if (
        orgId === 40 &&
        (panel[i].classList.contains("iframe") ||
          panel[i].classList.contains("Video"))
      ) {
        panel[i].classList.remove("container");
        panel[i].classList.remove("no-padding");
      }
    }
  }
};
export const indicatorsInitHandler = (flag) => {
  var carousels = document.getElementsByClassName("carousel");
  for (var i = 0; i < carousels.length; i++) {
    let carousel = carousels[i];
    let items = carousel?.querySelectorAll(".carousel-inner .item");
    let carouselIndicator = carousel.querySelector(".carousel-indicators");
    if (learnMoreDisplayFeature.includes(Number(getTenantOrgId()))) {
      const lastItem = items[items.length - 1];
      learnMoreClass.forEach((className) => {
        let lastItemLearnMore = lastItem.querySelector(`.${className}`);
        if (lastItemLearnMore?.classList.contains(className)) {
          lastItemLearnMore.style.display = "none";
        }
      });
    }
    if (items.length > 1) {
      //Hide learn more on single slide
      carouselIndicator.style.display = "block";

      learnMoreClass.forEach((learnMoreClass) => {
        if (carousel?.querySelector(`.${learnMoreClass}`)) {
          if (learnMoreDisplayShouldFlex?.includes(getTenantOrgId())) {
            if (
              Number(getTenantOrgId()) !== 48 ||
              (Number(getTenantOrgId()) === 48 &&
                learnMoreClass != "pb-learn-more-text")
            ) {
              carousel.querySelector(`.${learnMoreClass}`).style.display =
                "inline-flex";
            }
          } else if (learnMoreDisplayShouldTable?.includes(getTenantOrgId())) {
            carousel.querySelector(`.${learnMoreClass}`).style.display =
              "table-cell";
          } else {
            carousel.querySelector(`.${learnMoreClass}`).style.display =
              "block";
          }
        }
      });

      let indicators = "";
      for (let index = 0; index < items.length; index++) {
        let innerText =
          carouselIndicator?.querySelectorAll("li")[index]?.innerText;
        if (!innerText) innerText = "";
        let active = "";
        if (flag === 0) {
          if (index === flag) active += "active";
        } else if (flag) {
          if (index === flag) active += "active";
        } else {
          if (index === items.length - 1) active += "active";
        }
        indicators +=
          '<li class="' +
          active +
          '" data-bs-target="#' +
          carousel?.id +
          '" data-bs-slide-to="' +
          index +
          '"> ' +
          innerText +
          "</li>";
      }
      carouselIndicator.innerHTML = indicators;
    } else {
      if (carouselIndicator) {
        carouselIndicator.style.display = "none";
        carouselIndicator.innerHTML = "";
      }
      //Hide learn more on single slide
      learnMoreClass.forEach((learnMoreClass) => {
        if (carousel?.querySelector(`.${learnMoreClass}`))
          carousel.querySelector(`.${learnMoreClass}`).style.display = "none";
      });
      if (carousel?.querySelector(".right-arrow")?.style?.display)
        carousel.querySelector(".right-arrow").style.display = "none";
    }
  }
};

export const indicatorsSingleInitHandler = (flag) => {
  var carousels = document.getElementsByClassName("carousel");
  if (
    window.location.pathname.includes(RedirectTo("/site-edit")) ||
    window.location.pathname.includes(RedirectTo("/report-edit"))
  ) {
    let slideId = document
      .querySelector("#rootComponent")
      .getAttribute("slidesecclass-id");
    carousels = document.querySelectorAll("#" + slideId + " .carousel");
  }
  for (var i = 0; i < carousels.length; i++) {
    let carousel = carousels[i];
    let items = carousel?.querySelectorAll(".carousel-inner .item");
    let carouselIndicator = carousel.querySelector(".carousel-indicators");
    if (items.length > 1) {
      //Hide learn more on single slide
      carouselIndicator.style.display = "block";
      if (carousel?.querySelector(".learn-more-wrapper"))
        carousel.querySelector(".learn-more-wrapper").style.display = "block";
      let indicators = "";
      for (let index = 0; index < items.length; index++) {
        let active = "";
        if (flag === 0) {
          if (index === flag) active += "active";
        } else if (flag) {
          if (index === flag) active += "active";
        } else {
          if (index === items.length - 1) active += "active";
        }
        indicators +=
          '<li class="' +
          active +
          '" data-bs-target="#' +
          carousel?.id +
          '" data-bs-slide-to="' +
          index +
          '"> </li>';
      }
      carouselIndicator.innerHTML = indicators;
    } else {
      carouselIndicator.style.display = "none";
      //Hide learn more on single slide
      if (carousel?.querySelector(".learn-more-wrapper"))
        carousel.querySelector(".learn-more-wrapper").style.display = "none";
      carouselIndicator.innerHTML = "";
      if (carousel?.querySelector(".right-arrow")?.style?.display)
        carousel.querySelector(".right-arrow").style.display = "none";
    }
  }
};

export const arrowInitHandler = (init) => {
  var carousels = document.getElementsByClassName("carousel");
  for (var i = 0; i < carousels.length; i++) {
    let carousel = carousels[i];
    let prev = carousel.querySelector(
      ".carousel-control.carousel-control-prev span"
    );
    let next = carousel.querySelector(
      ".carousel-control.carousel-control-next span"
    );
    let items = carousel?.querySelectorAll(".carousel-inner .item");
    if (init === "init") {
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        element.classList.remove("active");
        if (index === 0) {
          element.classList.add("active");
        }
      }
    }
    if (items?.length > 1) {
      //Hide learn more on single slide
      if (carousel?.querySelector(".learn-more-wrapper")) {
        if (learnMoreDisplayShouldFlex?.includes(getTenantOrgId())) {
          carousel.querySelector(".learn-more-wrapper").style.display =
            "inline-flex";
        } else if (learnMoreDisplayShouldTable?.includes(getTenantOrgId())) {
          carousel.querySelector(".learn-more-wrapper").style.display =
            "table-cell";
        } else {
          carousel.querySelector(".learn-more-wrapper").style.display = "block";
        }
      }
      const first = carousel.querySelector(
        ".carousel-inner .item.active:nth-child(1)"
      );
      const last = carousel.querySelector(
        ".carousel-inner .item.active:nth-child(" + items.length + ")"
      );
      if (first) {
        if (prev?.style?.display) prev.style.display = "none";
        if (next?.style?.display) next.style.display = "block";
        let buttons = document.querySelectorAll(
          ".slide-operational-bar.operational-bar button"
        );
        for (let index = 0; index < buttons.length; index++) {
          const element = buttons[index];
          element.classList.remove("disabled");
          if (element?.title === icons.LEFT.title) {
            element.classList.add("disabled");
          }
          if (items?.length > 5 && element?.title === icons.ADD.title) {
            element.classList.add("disabled");
          }
        }
      } else if (last) {
        if (prev?.style?.display) prev.style.display = "block";
        if (next?.style?.display) next.style.display = "none";
        let buttons = document.querySelectorAll(
          ".slide-operational-bar.operational-bar button"
        );
        for (let index = 0; index < buttons.length; index++) {
          const element = buttons[index];
          element.classList.remove("disabled");
          if (element?.title === icons.RIGHT.title) {
            element.classList.add("disabled");
          }
          if (items?.length > 5 && element?.title === icons.ADD.title) {
            element.classList.add("disabled");
          }
        }
      } else {
        if (prev?.style?.display) prev.style.display = "block";
        if (next?.style?.display) next.style.display = "block";
        let buttons = document.querySelectorAll(
          ".slide-operational-bar.operational-bar button"
        );
        for (let index = 0; index < buttons.length; index++) {
          const element = buttons[index];
          element.classList.remove("disabled");
          if (items?.length > 5 && element?.title === icons.ADD.title) {
            element.classList.add("disabled");
          }
        }
      }
    } else {
      if (prev?.style?.display) prev.style.display = "none";
      if (next?.style?.display) next.style.display = "none";
      let buttons = document.querySelectorAll(
        ".slide-operational-bar.operational-bar button"
      );
      for (let index = 0; index < buttons.length; index++) {
        const element = buttons[index];
        element.classList.remove("disabled");
        if (element?.title === icons.RIGHT.title) {
          element.classList.add("disabled");
        }
        if (element?.title === icons.LEFT.title) {
          element.classList.add("disabled");
        }
        if (element?.title === icons.DELETE.title) {
          element.classList.add("disabled");
        }
      }
    }
  }
};

export const convertOnClickToOnClickPrivate = (className) => {
  try {
    let elements = document.querySelectorAll(`.${className}`);
    if (elements && elements.length > 0) {
      elements.forEach((ele) => {
        if (
          ele.getAttribute("onclick") === "openFundList(this)" ||
          ele.getAttribute("onclick") === "closeFundList(this)"
        ) {
          ele.removeAttribute("onclick");
        }
      });
    }
  } catch (error) {}
};

export const menuDropdownMigration = () => {
  document?.querySelector("#menu1")?.removeAttribute("data-bs-toggle");
  let dropdownMenu = document?.querySelector(".dropdown-menu");
  if (dropdownMenu && getTenantOrgId() !== "35") {
    dropdownMenu.style.display = "none";
    dropdownMenu.classList.remove("show");
  }
  // Nat geo 81
  let mySidenav = document?.querySelector("#mySidenav");
  if (mySidenav) mySidenav.style.width = "0";
  let menu = document?.querySelector(
    ".vcu-health-campaign .dropdown-menu.menu-list"
  );
  if (menu) {
    menu.classList.remove("menu-list");
    menu.classList.add("menu-side-open");
  }
};

(window.location.pathname.includes("/reports") ||
  window.location.pathname.includes("/sites")) &&
  document?.addEventListener("click", (e) => {
    if (e?.target?.closest(".slideSecClass .slider-collapsable-common")) {
      mobileAccordianClickHandler(e);
    }

    if (getTenantOrgId().includes("81")) {
      handleMouseClickeventForNatGeo(e);
    } else handleMouseClickevent(e);
  });

export const scrollIntoTop = () => {
  try {
    if (
      document?.querySelector(".back-to-top-btn.ui-link") &&
      getTenantOrgId() === "19"
    ) {
      document
        ?.querySelector(".back-to-top-btn.ui-link")
        .addEventListener("click", (e) => {
          e.preventDefault();
          document.getElementById("IdHeader").scrollIntoView();
        });
    }
  } catch (error) {}
};

const impactTenantEvent = ["74"];
export const impactSlideEvent = () => {
  setTimeout(() => {
    if (
      impactTenantEvent.includes(getTenantOrgId()) &&
      window?.impactBlockBoxAdjustment
    ) {
      window.impactBlockBoxAdjustment();
    }
  }, 1000);
};

document?.addEventListener("click", (e) => {
  if (e.target.closest(".inline-textEditor")) {
    $("#" + e?.target?.closest(".carousel")?.id).off("keydown.bs.carousel");
  }
  if (e.target.closest(".slick-list")) {
    e.target.closest(".slick-list").setAttribute("tabindex", 0);
    e.target.closest(".slick-list").focus();
  }
  let obj = e?.target?.closest(".carousel-control");
  if (obj?.classList?.contains("carousel-control-prev")) {
    //left
    let num = obj?.parentElement
      ?.querySelector(".carousel-indicators .active")
      ?.getAttribute("data-bs-slide-to");
    obj?.parentElement
      ?.querySelector(
        '.carousel-indicators [data-bs-slide-to="' + (Number(num) - 1) + '"]'
      )
      ?.click();
    resetArrow(obj?.parentElement, Number(num) - 1);
    updateCurrentFund(e?.target, Number(num) - 1);
    impactSlideEvent();
    autoPullUpFeature(e?.target?.closest(".carousel"));
    if (showCarouselIndicatorNumber.includes(Number(getTenantOrgId()))) {
      if (Number(getTenantOrgId()) === 1) {
        slideNumber(".REBRAND_2024");
      } else {
        slideNumber("");
      }
    }
  } else if (obj?.classList?.contains("carousel-control-next")) {
    //right
    let num = obj?.parentElement
      ?.querySelector(".carousel-indicators .active")
      .getAttribute("data-bs-slide-to");
    obj?.parentElement
      ?.querySelector(
        '.carousel-indicators [data-bs-slide-to="' + (Number(num) + 1) + '"]'
      )
      ?.click();
    resetArrow(obj?.parentElement, Number(num) + 1);
    updateCurrentFund(e?.target, Number(num) + 1);
    impactSlideEvent();
    autoPullUpFeature(e?.target?.closest(".carousel"));
    if (showCarouselIndicatorNumber.includes(Number(getTenantOrgId()))) {
      if (Number(getTenantOrgId()) === 1) {
        slideNumber(".REBRAND_2024");
      } else {
        slideNumber("");
      }
    }
  }
  if (
    e?.target?.tagName === "LI" &&
    e?.target?.parentNode?.classList?.contains("carousel-indicators")
  ) {
    let carouselListFunds = e?.target?.closest(".carousel-list-funds");
    if (carouselListFunds && !carouselListFunds?.classList?.contains("hide")) {
      carouselListFunds.classList.add("hide");
    }
    resetArrow(
      e?.target?.closest(".carousel"),
      Number(e?.target.getAttribute("data-bs-slide-to"))
    );
    updateCurrentFund(
      e?.target,
      Number(e?.target.getAttribute("data-bs-slide-to"))
    );
    if (showCarouselIndicatorNumber.includes(Number(getTenantOrgId()))) {
      if (Number(getTenantOrgId()) === 1) {
        slideNumber(".REBRAND_2024");
      } else {
        slideNumber("");
      }
    }
  }
  if (
    (e?.target?.innerText === "Learn More" ||
      e?.target?.innerText === "Next slide" ||
      e?.target?.innerText?.toLowerCase().trim() === "learn more" ||
      e?.target?.innerText?.toLowerCase().trim() === "learnmore" ||
      e?.target?.innerText?.toLowerCase().trim() === "continue" ||
      e?.target?.closest(".learnMoreLnk")) &&
    !e?.target?.closest(".report-cp-panel")
  ) {
    let objParentElement = e?.target?.closest(".carousel");
    if (objParentElement) {
      let ele = objParentElement?.querySelector(".carousel-control-next");
      if (ele) {
        ele.click();
      }
    }
    if (showCarouselIndicatorNumber.includes(Number(getTenantOrgId()))) {
      if (Number(getTenantOrgId()) === 1) {
        slideNumber(".REBRAND_2024");
      } else {
        slideNumber("");
      }
    }
  }
});

//To calculate slide number below carousel indicator like 02/03, 03/03
export const slideNumber = (selector) => {
  try {
    let numberFormat = "";
    if (numberFormatStartWithZero.includes(Number(getTenantOrgId()))) {
      numberFormat = "0";
    } else {
      numberFormat = "";
    }
    let CarouselParents = document?.querySelectorAll(
      selector + ".standard-template .carousel-indicators"
    );

    CarouselParents.forEach((CarouselParent, index) => {
      if (
        CarouselParent?.style.display === "none" &&
        CarouselParent?.parentElement?.querySelector(".SlideNumber")
      ) {
        CarouselParent.parentElement.querySelector(
          ".SlideNumber"
        ).style.display = "none";
      } else {
        if (CarouselParent?.parentElement?.querySelector(".SlideNumber")) {
          CarouselParent.parentElement.querySelector(
            ".SlideNumber"
          ).style.display = "block";
          let liElements = CarouselParent?.querySelectorAll("li");
          let liCount = liElements.length;
          let totalSlideId = `TotalSlide_${index}`;
          CarouselParent.parentElement.querySelector(".TotalSlide").id =
            totalSlideId;
          let displayElementT = document?.getElementById(`TotalSlide_${index}`);

          if (displayElementT) {
            displayElementT.innerHTML = liCount
              .toString()
              .padStart(2, numberFormat);
          }

          liElements.forEach((li) => {
            if (li?.classList?.contains("active")) {
              let slideToValue = parseInt(li?.getAttribute("data-bs-slide-to"));
              let incrementedValue = slideToValue + 1;
              let activeSlideId = `ActiveSlide_${index}`;
              CarouselParent.parentElement.querySelector(".ActiveSlide").id =
                activeSlideId;
              let displayElementA = document?.getElementById(
                `ActiveSlide_${index}`
              );

              if (displayElementA) {
                displayElementA.innerHTML = incrementedValue
                  .toString()
                  .padStart(2, numberFormat);
              }
            }
          });
        }
      }
    });
  } catch (err) {
    console.log(err);
  }
};

export const autoPullUpFeature = (elements) => {
  setTimeout(() => {
    let leftColumnHeight = 0;
    let rightColumnHeight = 0;
    let articles = elements?.querySelectorAll(
      ".item.active .auto-pull-up-block-wrapper .auto-pull-up-block"
    );

    for (var i = 0; i < articles?.length; i++) {
      if (leftColumnHeight > rightColumnHeight) {
        rightColumnHeight += articles[i].offsetHeight + 17;
        articles[i].classList.add("right");
      } else {
        leftColumnHeight += articles[i].offsetHeight + 17;
      }
    }
  }, 2000);
};

const handleMouseClickevent = (e) => {
  try {
    // Menu Dropdown
    if (
      e.target.closest("header .dropdown #menu1") ||
      e.target.closest("header .menu-close") ||
      e.target.closest("header .close-menu")
    ) {
      Number(getTenantOrgId()) === 1 &&
        window?.dropdownHandler &&
        window.dropdownHandler();
      if (Number(getTenantOrgId()) !== 35) {
        $(".dropdown-menu,.menu-list").slideToggle("fast", function () {
          Number(getTenantOrgId()) !== 1 &&
            window?.dropdownHandler &&
            window.dropdownHandler();
        });
      } else if (Number(getTenantOrgId()) === 35) {
        window.dropDownMenuForMuhlen && window.dropDownMenuForMuhlen();
      }
      e.stopPropagation();
    } else {
      if (Number(getTenantOrgId()) !== 35) {
        let menu =
          document?.querySelector("header .dropdown-menu") ||
          document?.querySelector("header .menu-list");
        if (
          menu?.style?.display !== "" &&
          (!menu?.style?.display || menu?.style?.display === "block")
        ) {
          document.querySelector("header .dropdown #menu1")?.click();
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const handleMouseClickeventForNatGeo = (e) => {
  try {
    // Menu Dropdown
    if (e.target.closest("header .dropdown") || e.target.closest(".close")) {
      $(".dropdown-menu").slideToggle("fast", function () {
        window?.dropdownHandler && window.dropdownHandler();
        $("#mySidenav").animate({
          width: "0%",
        });
      });
      e.stopPropagation();
    } else {
      let menu =
        document?.querySelector("header .dropdown-menu") ||
        document?.querySelector("header .menu-list") ||
        document?.querySelector("header .menu-list-brand");
      if (
        menu !== null &&
        (!menu.style.display || menu.style.display === "block")
      ) {
        document.querySelector("header .dropdown").click();
      }
    }
  } catch (error) {
    console.log(error);
  }
};
export const convertImgSrcToIframeVideo = () =>
  WistiaEditorIFrame(".assetReplacerClassForFundDescription");

const updateCurrentFund = (eTarget, num) => {
  const carousel = eTarget?.closest(".carousel")?.parentElement;
  let currentFundElement = "";
  if (changeClassForCurrentFund.includes(getTenantOrgId())) {
    if (carousel?.querySelectorAll(".impact-only .current-fund")[0]) {
      currentFundElement = carousel?.querySelectorAll(
        ".impact-only .current-fund"
      )[0];
    } else {
      currentFundElement = carousel?.querySelectorAll(".current-fund")[0];
    }
  } else {
    if (Number(getTenantOrgId()) === 19 && carousel.id === "FUND_IMPACT") {
      currentFundElement = carousel?.querySelector(".current-fund.recipient");
    } else {
      currentFundElement = carousel?.querySelector(".current-fund");
    }
  }
  if (currentFundElement) currentFundElement.innerText = num + 1;
};

const resetArrow = (carousel, num) => {
  if (
    carousel?.querySelector(".carousel-control-prev span") &&
    carousel.querySelector(".carousel-control-next span")
  ) {
    if (adaCheckerForLeftAndRightArrow?.includes(Number(getTenantOrgId()))) {
      carousel
        ?.querySelectorAll(".carousel-indicators li")
        ?.forEach((indicator) => {
          indicator.removeAttribute("aria-hidden");
          indicator.setAttribute("tabindex", 0);
        });
      carousel
        .querySelector(".carousel-control-next")
        .setAttribute("aria-hidden", "false");
      carousel
        .querySelector(".carousel-control-next")
        .setAttribute("tabindex", 0);
      carousel
        .querySelector(".carousel-control-prev")
        .setAttribute("aria-hidden", "false");
      carousel
        .querySelector(".carousel-control-prev")
        .setAttribute("tabindex", 0);
      carousel
        .querySelector(".carousel-control-next")
        .classList.remove("focus-removed");
      carousel
        .querySelector(".carousel-control-prev")
        .classList.remove("focus-removed");
    }
    carousel.querySelector(".carousel-control-prev span").style.display =
      "block";
    carousel.querySelector(".carousel-control-next span").style.display =
      "block";

    let items = carousel?.querySelectorAll(".carousel-inner .item");
    if (num === 0) {
      if (adaCheckerForLeftAndRightArrow?.includes(Number(getTenantOrgId()))) {
        carousel
          ?.querySelectorAll(".carousel-indicators li")
          ?.forEach((indicator) => {
            indicator.setAttribute("aria-hidden", "true");
            indicator.setAttribute("tabindex", "-1");
          });
        carousel
          .querySelector(".carousel-control-prev")
          .setAttribute("aria-hidden", "true");
        carousel
          .querySelector(".carousel-control-prev")
          .setAttribute("tabindex", "-1");
        carousel
          .querySelector(".carousel-control-prev")
          .classList.add("focus-removed");
      }
      carousel.querySelector(".carousel-control-prev span").style.display =
        "none";
      let buttons = document.querySelectorAll(
        ".slide-operational-bar.operational-bar button"
      );
      for (let index = 0; index < buttons.length; index++) {
        const element = buttons[index];
        element.classList.remove("disabled");
        if (element?.title === icons.LEFT.title) {
          element.classList.add("disabled");
        }
        if (items?.length > 5 && element?.title === icons.ADD.title) {
          element.classList.add("disabled");
        }
      }
    } else if (
      num ===
      carousel?.querySelectorAll(".carousel-indicators li").length - 1
    ) {
      if (adaCheckerForLeftAndRightArrow?.includes(Number(getTenantOrgId()))) {
        carousel
          ?.querySelectorAll(".carousel-indicators li")
          ?.forEach((indicator) => {
            indicator.setAttribute("aria-hidden", "true");
            indicator.setAttribute("tabindex", "-1");
          });
        carousel
          .querySelector(".carousel-control-next")
          .setAttribute("aria-hidden", "true");
        carousel
          .querySelector(".carousel-control-next")
          .setAttribute("tabindex", "-1");
        carousel
          .querySelector(".carousel-control-next")
          .classList.add("focus-removed");
      }
      carousel.querySelector(".carousel-control-next span").style.display =
        "none";
      let buttons = document.querySelectorAll(
        ".slide-operational-bar.operational-bar button"
      );
      for (let index = 0; index < buttons.length; index++) {
        const element = buttons[index];
        element.classList.remove("disabled");
        if (element?.title === icons.RIGHT.title) {
          element.classList.add("disabled");
        }
        if (items?.length > 5 && element?.title === icons.ADD.title) {
          element.classList.add("disabled");
        }
      }
    } else {
      let buttons = document.querySelectorAll(
        ".slide-operational-bar.operational-bar button"
      );
      for (let index = 0; index < buttons.length; index++) {
        const element = buttons[index];
        element.classList.remove("disabled");
        if (items?.length > 5 && element?.title === icons.ADD.title) {
          element.classList.add("disabled");
        }
      }
    }
  }
};

export const updateCarouselId = (isPanelAdding) => {
  let carouselId = "";
  let carousel = document?.querySelectorAll(".slideSecClass .carousel");
  for (let index = 0; index < carousel.length; index++) {
    const element = carousel[index];
    let array = document.querySelectorAll("#" + element?.id);
    if (array?.length > 1) {
      let num = 1;
      for (let i = 0; i < array?.length; i++) {
        const item = array[i];

        if (carouselId === item?.id && item?.id?.includes("myCarousel")) {
          let newCarouselId = 0;
          newCarouselId = item?.id + index + num;
          addClassOnCarausal(item);
          item.id = newCarouselId;
          //carousel-indicators
          item
            ?.querySelectorAll(".carousel-indicators li")
            ?.forEach((indicator) => {
              indicator.setAttribute("data-bs-target", "#" + newCarouselId);
            });
          carouselId = newCarouselId;
          num = num + 1;
        } else {
          carouselId = item?.id;
        }
      }
    }
  }
};

const addClassOnCarausal = (element) => {
  if (addClassToCarausalSectionChecker?.includes(getTenantOrgId())) {
    let array = element?.classList;
    let isAlreadyExist = false;
    for (let index = 0; index < array.length; index++) {
      const ele = array[index];
      if (element?.id?.includes(ele)) {
        isAlreadyExist = true;
        break;
      }
    }
    if (!isAlreadyExist) {
      element.classList.add(element.id);
    }
  }
};

export const getTenantOrgId = () => {
  let TenantOrgIdStr = "";
  let lSTenantOrgId = localStorage.getItem("TenantOrgId");
  if (lSTenantOrgId !== "undefined") {
    let TenantOrgId = JSON.parse(lSTenantOrgId);
    if (TenantOrgId) TenantOrgIdStr = TenantOrgId.toString();
  }
  return TenantOrgIdStr;
};

export const setupContactHtmlMigrateNeeded = () => {
  let array = document.querySelectorAll(".doheading .dotitle");
  array.forEach((element) => {
    if (element.innerHTML.includes("CC officeNO")) {
      element.innerHTML =
        '<span class="DOdetailsHeading DOdetailsHeading1 DOdetailsHeading1-off">Office:</span><span>CC officeNO</span>';
    }
    if (element.innerHTML.includes("CC mobileNO")) {
      element.innerHTML =
        '<span class="DOdetailsHeading DOdetailsHeading1 DOdetailsHeading1-off">Cell:</span><span>CC mobileNO</span>';
    }
    if (element.innerHTML.includes("CC faxNO")) {
      element.innerHTML =
        '<span class="DOdetailsHeading DOdetailsHeading1 DOdetailsHeading1-off">Fax:</span><span>CC faxNO</span>';
    }
  });
};

export const setupContactInfo = (data, systemAdminInfo) => {
  setTimeout(() => {
    let contactPageWrapper = document?.querySelector(".contact-page-wrapper")
      ? ".contact-page-wrapper"
      : ".report-brand-contact-page-wrapper";
    setElementValue(
      document?.querySelector(".CC-contact-image"),
      data?.tblRecords?.user?.contactDatas[0]?.profileImage,
      "src"
    );
    setElementValue(
      document?.querySelector('[sectionid="contact2"]') ||
        document.querySelector("span.cc-name"),
      data?.tblRecords?.user?.colFullName
    );
    document?.querySelectorAll('[sectionid="contact2"]').length > 1 &&
      setElementValue(
        document?.querySelectorAll('[sectionid="contact2"]')[1] ||
          document.querySelector("span.cc-name"),
        data?.tblRecords?.user?.colFullName
      );

    setElementValue(
      document?.querySelector("#bioUpClassYear"),
      data?.tblRecords?.user?.contactDatas[0]?.classYear
        ? ", '" + data?.tblRecords?.user?.contactDatas[0]?.classYear.slice(-2)
        : "",
      "innerHTML"
    );
    document?.querySelectorAll("#bioUpClassYear")?.length > 1 &&
      setElementValue(
        document?.querySelectorAll("#bioUpClassYear")[1],
        data?.tblRecords?.user?.contactDatas[0]?.classYear
          ? ", '" + data?.tblRecords?.user?.contactDatas[0]?.classYear.slice(-2)
          : "",
        "innerHTML"
      );
    setElementValue(
      document?.querySelector('[sectionid="contact4"]'),
      data?.tblRecords?.user?.contactDatas[0]?.vcfPath,
      "href"
    );
    setElementValue(
      document?.querySelector('[sectionid="contact5"] .c-info') ||
        document?.querySelector(
          '[sectionid="contact5"] span:not(.DOdetailsHeading)'
        ),
      data?.tblRecords?.user?.contactDatas[0]?.offNum,
      null,
      null,
      !data?.tblRecords?.user?.contactDatas[0]?.offNum
    );
    document
      ?.querySelectorAll(".personal-details-bottom-txt")
      .forEach((element) => {
        if (
          ccTitleForTenant.includes(getTenantOrgId()) &&
          element?.style?.display !== "none" &&
          (element?.innerText?.trim() === "CC Title" ||
            element?.innerText?.trim() === "CC TITLE")
        ) {
          element.setAttribute("sectionid", "contact3");
        }
      });

    document?.querySelectorAll('[sectionid="contact3"]')?.forEach((element) => {
      setElementValue(
        element,
        data?.tblRecords?.user?.contactDatas[0]?.bioTitle,
        "innerHTML"
      );
    });

    if (contactPageHideParentIfBioNotPresent.includes(getTenantOrgId())) {
      setElementValue(
        document?.querySelector('[sectionid="contact9"] span') ||
          document?.querySelector('[sectionid="contact9"]'),
        data?.tblRecords?.user?.contactDatas[0]?.bioDescrip,
        "hideParent"
      );
    } else {
      setElementValue(
        document?.querySelector('[sectionid="contact9"] span') ||
          document?.querySelector('[sectionid="contact9"]'),
        data?.tblRecords?.user?.contactDatas[0]?.bioDescrip,
        "innerHTML"
      );
    }
    setElementValue(
      document?.querySelector('[sectionid="contact8"] .c-info') ||
        document?.querySelector(
          '[sectionid="contact8"] span:not(.DOdetailsHeading)'
        ),
      data?.tblRecords?.user?.contactDatas[0]?.mobNum ||
        systemAdminInfo?.colContactNo,
      null,
      null,
      !(
        data?.tblRecords?.user?.contactDatas[0]?.mobNum ||
        systemAdminInfo?.colContactNo
      )
    );

    setElementValue(
      document?.querySelector('[sectionid="contact7"] .c-info') ||
        document?.querySelector(
          '[sectionid="contact7"] span:not(.DOdetailsHeading)'
        ),
      data?.tblRecords?.user?.contactDatas[0]?.faxNum,
      null,
      null,
      !data?.tblRecords?.user?.contactDatas[0]?.faxNum
    );

    setTimeout(() => {
      if (
        data?.tblRecords?.user?.contactDatas[0]?.faxNum &&
        document?.querySelector('[sectionid="contact7"]') &&
        document?.querySelector(
          '[sectionid="contact7"] span:not(.DOdetailsHeading)'
        ) === null &&
        Number(getTenantOrgId()) === 19
      ) {
        document.querySelector(
          '[sectionid="contact7"]'
        ).innerHTML = `<div class="dotitle ellipsis" tabindex="0"><span class="DOdetailsHeading DOdetailsHeading1">Fax:</span><span class="contact-page-info">${data?.tblRecords?.user?.contactDatas[0]?.faxNum}</span>
             </div>`;
      }
    }, 1000);

    setElementValue(
      document?.querySelector(contactPageWrapper + " .email .c-info a") ||
        document?.querySelector('[sectionid="contact6"] a') ||
        document?.querySelector(".email .c-info a"),
      "mailto:" + data?.tblRecords?.user?.contactDatas[0]?.bioEmail,
      "href"
    );
    setElementValue(
      document?.querySelector(contactPageWrapper + " .email .c-info a") ||
        document?.querySelector('[sectionid="contact6"] a') ||
        document?.querySelector(".email .c-info a"),
      data?.tblRecords?.user?.contactDatas[0]?.bioEmail
    );
    if (document?.querySelector(".prefix-replacer")) {
      if (
        data?.tblRecords?.user?.contactDatas[0]?.bioPrefix
          ?.trim()
          ?.replace("None", "")
      ) {
        setElementValue(
          document?.querySelector(".prefix-replacer"),
          data?.tblRecords?.user?.contactDatas[0]?.bioPrefix + " ",
          "innerHTML"
        );
      } else if (document?.querySelector(".prefix-replacer")) {
        document.querySelector(".prefix-replacer").style.display = "none";
      }
    }
    if (document?.querySelector(".suffix-replacer")) {
      if (
        data?.tblRecords?.user?.contactDatas[0]?.bioSuffix
          ?.trim()
          ?.replace("None", "")
      ) {
        if (Number(getTenantOrgId()) === 90) {
          setElementValue(
            document?.querySelector(".suffix-replacer"),
            " " + data?.tblRecords?.user?.contactDatas[0]?.bioSuffix,
            "innerHTML"
          );
        } else if (
          Number(getTenantOrgId()) === 36 &&
          document
            .getElementsByTagName("body")[0]
            ?.classList.contains("REBRAND_2024_BODY")
        ) {
          setElementValue(
            document?.querySelector(".suffix-replacer"),
            " " + data?.tblRecords?.user?.contactDatas[0]?.bioSuffix,
            "innerHTML"
          );
        } else {
          setElementValue(
            document?.querySelector(".suffix-replacer"),
            ", " + data?.tblRecords?.user?.contactDatas[0]?.bioSuffix,
            "innerHTML"
          );
        }
      } else if (document?.querySelector(".suffix-replacer")) {
        document.querySelector(".suffix-replacer").style.display = "none";
      }
    }
    let anchor = "";
    if (
      Number(getTenantOrgId()) === 8 ||
      (Number(getTenantOrgId()) === 1 &&
        !document?.querySelector("body.REBRAND_2024_BODY")) ||
      (Number(getTenantOrgId()) === 7 &&
        !document?.body?.classList?.contains("palette20"))
    ) {
      contactPageWrapper = ".contact-page";
      anchor = " a";
    } else if (Number(getTenantOrgId()) === 17) {
      if (document?.querySelector(".contact-page #bioUpwebUrl a")) {
        contactPageWrapper = ".contact-page";
        anchor = " a";
      } else {
        contactPageWrapper = ".contact-page";
      }
    }

    if (Number(getTenantOrgId()) === 18 || Number(getTenantOrgId()) === 17) {
      setUpContactAll(data, systemAdminInfo);
    } else {
      // Media
      setElementValue(
        document?.querySelector(
          contactPageWrapper + " #bioUpfacUrl" + anchor
        ) || document?.querySelector('[href="bioUpfacUrlhref"]'),
        data?.tblRecords?.user?.contactDatas[0]?.facUrl
          ? data?.tblRecords?.user?.contactDatas[0]?.facUrl
          : systemAdminInfo?.colFacebookUrl,
        "media",
        false,
        false,
        document?.querySelector('[href="bioUpfacUrlhref"]') !== null
      );
      setElementValue(
        document?.querySelector(
          contactPageWrapper + " #bioUpwebUrl" + anchor
        ) || document?.querySelector('[href="bioUpwebUrlhref"]'),
        data?.tblRecords?.user?.contactDatas[0]?.webUrl
          ? data?.tblRecords?.user?.contactDatas[0]?.webUrl
          : systemAdminInfo?.colWebAddress,
        "media",
        false,
        false,
        document?.querySelector('[href="bioUpwebUrlhref"]') !== null
      );

      if (Number(getTenantOrgId()) === 29) {
        setElementValue(
          document?.querySelector(
            contactPageWrapper +
              " #bioUpwebUrl" +
              anchor +
              ", " +
              contactPageWrapper +
              " #bioUpPulseUrl" +
              anchor
          ) || document?.querySelector('[href="bioUpwebUrlhref"]'),
          data?.tblRecords?.user?.contactDatas[0]?.webUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.webUrl
            : systemAdminInfo?.colWebAddress,
          "media",
          false,
          false,
          document?.querySelector('[href="bioUpwebUrlhref"]') !== null
        );
      }

      setElementValue(
        document?.querySelector(
          contactPageWrapper + " #bioUptwtUrl" + anchor
        ) ||
          document?.querySelector(
            '[href="https://twitter.com/bioUptwtUrlhref"]'
          ),
        data?.tblRecords?.user?.contactDatas[0]?.twtUrl
          ? data?.tblRecords?.user?.contactDatas[0]?.twtUrl
          : systemAdminInfo?.colTwitterUrl,
        "media",
        true,
        false,
        document?.querySelector(
          '[href="https://twitter.com/bioUptwtUrlhref"]'
        ) !== null
      );
      setElementValue(
        document?.querySelector(
          contactPageWrapper + " #bioUpinstaUrl" + anchor
        ) || document?.querySelector('[href="bioUpinstaUrlhref"]'),
        data?.tblRecords?.user?.contactDatas[0]?.colInstagramUrl
          ? data?.tblRecords?.user?.contactDatas[0]?.colInstagramUrl
          : systemAdminInfo?.colInstagramUrl,
        "media",
        false,
        false,
        document?.querySelector('[href="bioUpinstaUrlhref"]') !== null
      );
      setElementValue(
        document?.querySelector(
          contactPageWrapper + " #bioUplinkUrl" + anchor
        ) || document?.querySelector('[href="bioUplinkUrlhref"]'),
        data?.tblRecords?.user?.contactDatas[0]?.linkUrl
          ? data?.tblRecords?.user?.contactDatas[0]?.linkUrl
          : systemAdminInfo?.colLinkedinUrl,
        "media",
        false,
        false,
        document?.querySelector('[href="bioUplinkUrlhref"]') !== null
      );
    }
  }, 10);
};

export const setUpContactAll = (data, systemAdminInfo) => {
  setTimeout(() => {
    let contactPageWrapper = document?.querySelector(".contact-page-wrapper")
      ? ".contact-page-wrapper"
      : ".report-brand-contact-page-wrapper";

    document?.querySelectorAll(".CC-contact-image").forEach((element) => {
      setElementValue(
        element,
        data?.tblRecords?.user?.contactDatas[0]?.profileImage,
        "src"
      );
    });

    document?.querySelectorAll('[sectionid="contact2"]').forEach((ele) => {
      setElementValue(ele, data?.tblRecords?.user?.colFullName);
    });

    if (
      Number(getTenantOrgId()) === 17 &&
      document?.querySelectorAll(".personal-details-bottom-txt").length > 0
    ) {
      document
        .querySelectorAll(".personal-details-bottom-txt")
        .forEach((ele) => {
          ele.setAttribute("sectionid", "contact3");
        });

      document?.querySelectorAll('[sectionid="contact3"]').forEach((ele) => {
        setElementValue(
          ele,
          data?.tblRecords?.user?.contactDatas[0]?.bioTitle,
          "innerHTML"
        );
      });
    }

    document?.querySelectorAll("#bioUpClassYear").forEach((ele) => {
      setElementValue(
        ele,
        data?.tblRecords?.user?.contactDatas[0]?.classYear
          ? ", '" + data?.tblRecords?.user?.contactDatas[0]?.classYear.slice(-2)
          : "",
        "innerHTML"
      );
    });

    document?.querySelectorAll('[sectionid="contact4"]').forEach((ele) => {
      setElementValue(
        ele,
        data?.tblRecords?.user?.contactDatas[0]?.vcfPath,
        "href"
      );
    });

    document
      ?.querySelectorAll('[sectionid="contact5"] span:not(.DOdetailsHeading)')
      .forEach((ele) => {
        setElementValue(
          ele,
          data?.tblRecords?.user?.contactDatas[0]?.offNum,
          null,
          null,
          !data?.tblRecords?.user?.contactDatas[0]?.offNum
        );
      });

    document?.querySelectorAll('[sectionid="contact3"]').forEach((ele) => {
      setElementValue(
        ele,
        data?.tblRecords?.user?.contactDatas[0]?.bioTitle,
        "innerHTML"
      );
    });
    document?.querySelectorAll('[sectionid="contact9"]').forEach((ele) => {
      setElementValue(
        ele,
        data?.tblRecords?.user?.contactDatas[0]?.bioDescrip,
        "innerHTML"
      );
    });

    (
      document?.querySelectorAll('[sectionid="contact8"] .c-info') ||
      document?.querySelectorAll(
        '[sectionid="contact8"] span:not(.DOdetailsHeading)'
      )
    ).forEach((ele) => {
      setElementValue(
        ele,
        data?.tblRecords?.user?.contactDatas[0]?.mobNum ||
          systemAdminInfo?.colContactNo,
        null,
        null,
        !(
          data?.tblRecords?.user?.contactDatas[0]?.mobNum ||
          systemAdminInfo?.colContactNo
        )
      );
    });
    document?.querySelectorAll('[sectionid="contact7"] .c-info') ||
      document
        ?.querySelectorAll('[sectionid="contact7"] span:not(.DOdetailsHeading)')
        .forEach((ele) => {
          setElementValue(
            ele,
            data?.tblRecords?.user?.contactDatas[0]?.faxNum,
            null,
            null,
            !data?.tblRecords?.user?.contactDatas[0]?.faxNum
          );
        });

    document?.querySelectorAll(contactPageWrapper + " .email .c-info a") ||
      document?.querySelectorAll('[sectionid="contact6"] a').forEach((ele) => {
        setElementValue(
          ele,
          "mailto:" + data?.tblRecords?.user?.contactDatas[0]?.bioEmail,
          "href"
        );
      });

    document?.querySelectorAll(contactPageWrapper + " .email .c-info a") ||
      document?.querySelectorAll('[sectionid="contact6"] a').forEach((ele) => {
        setElementValue(ele, data?.tblRecords?.user?.contactDatas[0]?.bioEmail);
      });

    if (document?.querySelector(".prefix-replacer")) {
      if (
        data?.tblRecords?.user?.contactDatas[0]?.bioPrefix
          ?.trim()
          ?.replace("None", "")
      ) {
        setElementValue(
          document?.querySelector(".prefix-replacer"),
          data?.tblRecords?.user?.contactDatas[0]?.bioPrefix + " ",
          "innerHTML"
        );
      } else if (document?.querySelector(".prefix-replacer")) {
        document.querySelector(".prefix-replacer").style.display = "none";
      }
    }
    if (document?.querySelector(".suffix-replacer")) {
      if (
        data?.tblRecords?.user?.contactDatas[0]?.bioSuffix
          ?.trim()
          ?.replace("None", "")
      ) {
        setElementValue(
          document?.querySelector(".suffix-replacer"),
          ", " + data?.tblRecords?.user?.contactDatas[0]?.bioSuffix,
          "innerHTML"
        );
      } else if (document?.querySelector(".suffix-replacer")) {
        document.querySelector(".suffix-replacer").style.display = "none";
      }
    }
    let anchor = "";
    if (Number(getTenantOrgId()) === 8 || Number(getTenantOrgId()) === 18) {
      contactPageWrapper = ".contact-page";
      anchor = " a";
    }

    // Media
    document
      ?.querySelectorAll(contactPageWrapper + " #bioUpfacUrl")
      .forEach((ele) => {
        setElementValue(
          ele,
          data?.tblRecords?.user?.contactDatas[0]?.facUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.facUrl
            : systemAdminInfo?.colFacebookUrl,
          "media"
        );
      });

    document
      ?.querySelectorAll(contactPageWrapper + " #bioUpwebUrl")
      .forEach((ele) => {
        setElementValue(
          ele,
          data?.tblRecords?.user?.contactDatas[0]?.webUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.webUrl
            : systemAdminInfo?.colWebAddress,
          "media"
        );
      });

    document
      ?.querySelectorAll(contactPageWrapper + " #bioUptwtUrl")
      .forEach((ele) => {
        setElementValue(
          ele,
          data?.tblRecords?.user?.contactDatas[0]?.twtUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.twtUrl
            : systemAdminInfo?.colTwitterUrl,
          "media",
          true
        );
      });

    document
      ?.querySelectorAll(contactPageWrapper + " #bioUpinstaUrl")
      .forEach((ele) => {
        setElementValue(
          ele,
          data?.tblRecords?.user?.contactDatas[0]?.colInstagramUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.colInstagramUrl
            : systemAdminInfo?.colInstagramUrl,
          "media"
        );
      });

    document
      ?.querySelectorAll(contactPageWrapper + " #bioUplinkUrl")
      .forEach((ele) => {
        setElementValue(
          ele,
          data?.tblRecords?.user?.contactDatas[0]?.linkUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.linkUrl
            : systemAdminInfo?.colLinkedinUrl,
          "media"
        );
      });
    document
      ?.querySelectorAll(contactPageWrapper + " #bioUpsnapUrl")
      .forEach((ele) => {
        setElementValue(
          ele,
          data?.tblRecords?.user?.contactDatas[0]?.colSnapchatUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.colSnapchatUrl
            : systemAdminInfo?.colLinkedinUrl,
          "media"
        );
      });
  }, 300);
};

export const setupGreetingImage = (data, systemAdminInfo, isSite) => {
  if (isSite) {
    setElementValue(
      document?.querySelector(".CC-site-greeting-image") ||
        document?.querySelector(".CC-report-greeting-image") ||
        document?.querySelector(".CC-greeting-profile-image"),
      data?.tblRecords?.user?.contactDatas[0]?.profileImage,
      "greetingSrc"
    );
  } else {
    setElementValue(
      document?.querySelector(".CC-report-greeting-image"),
      data?.tblRecords?.user?.contactDatas[0]?.profileImage,
      "greetingSrc"
    );
  }
};

export const setupFooterInfo = (data, systemAdminInfo) => {
  //Address info details
  if (
    !data?.tblRecords?.user?.contactDatas[0]?.colAddressName &&
    !data?.tblRecords?.user?.contactDatas[0]?.colAddress1 &&
    !data?.tblRecords?.user?.contactDatas[0]?.colAddress2 &&
    !data?.tblRecords?.user?.contactDatas[0]?.colAddress3 &&
    !data?.tblRecords?.user?.contactDatas[0]?.colAddress4 &&
    !data?.tblRecords?.user?.contactDatas[0]?.colCity &&
    !data?.tblRecords?.user?.contactDatas[0]?.colStateProvince &&
    !data?.tblRecords?.user?.contactDatas[0]?.colZipPostalCode &&
    !data?.tblRecords?.user?.contactDatas[0]?.colCountry
  ) {
    if (footerAddressReplacerOrHideInnerChild.includes(getTenantOrgId())) {
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddressName"] span'),
        systemAdminInfo?.colAddress1,
        "replaceOrHideChild"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddress1"]'),
        systemAdminInfo?.colAddress2,
        "replaceOrHideChild"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddress2"] span'),
        systemAdminInfo?.colAddress3,
        "replaceOrHideChild"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddress3"] span'),
        systemAdminInfo?.colAddress4,
        "replaceOrHideChild"
      );
    } else {
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddressName"]'),
        systemAdminInfo?.colAddress1,
        "replaceOrHide"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddress1"]'),
        systemAdminInfo?.colAddress2,
        "replaceOrHide"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddress2"]'),
        systemAdminInfo?.colAddress3,
        "replaceOrHide"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddress3"]'),
        systemAdminInfo?.colAddress4,
        "replaceOrHide"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddress4"]'),
        systemAdminInfo?.colAddress4,
        "replaceOrHide"
      );
    }
    setElementValue(
      document?.querySelector('footer [sectionid="ccCity"]'),
      systemAdminInfo?.colCity,
      "replaceOrHide"
    );
    setElementValue(
      document?.querySelector('footer [sectionid="ccState"]'),
      systemAdminInfo?.colStateProvince,
      "replaceOrHide"
    );
    setElementValue(
      document?.querySelector('footer [sectionid="ccZipCode"]'),
      systemAdminInfo?.colZipPostalCode,
      "replaceOrHide"
    );
    setElementValue(
      document?.querySelector('footer [sectionid="ccCountry"]'),
      systemAdminInfo?.colCountry,
      "replaceOrHide"
    );
    if (Number(getTenantOrgId()) !== 18) {
      setElementValue(
        document?.querySelector('footer [sectionid="contact6"] a'),
        data?.tblRecords?.user?.contactDatas[0]?.bioEmail,
        "emailReplace"
      );
    }

    let footerEmailText = "";
    let getElement = document
      ?.querySelector("#IdFooter")
      ?.getElementsByTagName("a");
    if (getElement && getElement.length > 0) {
      for (let i = 0; i < getElement.length; i++) {
        if (
          getElement[i].getAttribute("href") === "mailto:CC-Email@" ||
          (Number(getTenantOrgId()) === 12 &&
            getElement[i]?.innerText === "CC-Email@")
        ) {
          footerEmailText = getElement[i];
          if (footerEmailText?.innerText === "CC-Email@") {
            setElementValue(
              footerEmailText,
              data?.tblRecords?.user?.contactDatas[0]?.bioEmail,
              "emailReplaceInnerText"
            );
          }
        }
      }
    }
  } else {
    if (footerAddressReplacerOrHideInnerChild.includes(getTenantOrgId())) {
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddressName"] span'),
        data?.tblRecords?.user?.contactDatas[0]?.colAddressName,
        "replaceOrHideChild"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddress1"]'),
        data?.tblRecords?.user?.contactDatas[0]?.colAddress1,
        "replaceOrHideChild"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddress2"] span'),
        data?.tblRecords?.user?.contactDatas[0]?.colAddress2,
        "replaceOrHideChild"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddress3"] span'),
        data?.tblRecords?.user?.contactDatas[0]?.colAddress3,
        "replaceOrHideChild"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddress4"] span'),
        data?.tblRecords?.user?.contactDatas[0]?.colAddress4,
        "replaceOrHideChild"
      );
    } else {
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddressName"]'),
        data?.tblRecords?.user?.contactDatas[0]?.colAddressName,
        "replaceOrHide"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddress1"]'),
        data?.tblRecords?.user?.contactDatas[0]?.colAddress1,
        "replaceOrHide"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddress2"]'),
        data?.tblRecords?.user?.contactDatas[0]?.colAddress2,
        "replaceOrHide"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddress3"]'),
        data?.tblRecords?.user?.contactDatas[0]?.colAddress3,
        "replaceOrHide"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccAddress4"]'),
        data?.tblRecords?.user?.contactDatas[0]?.colAddress4,
        "replaceOrHide"
      );
    }

    if (
      Number(getTenantOrgId()) === 12 &&
      document?.querySelector('footer [sectionid="ccCity"] .u-city')
    ) {
      setElementValue(
        document?.querySelector('footer [sectionid="ccCity"] .u-city'),
        data?.tblRecords?.user?.contactDatas[0]?.colCity,
        "replaceOrHide"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccCity"] .u-state'),
        data?.tblRecords?.user?.contactDatas[0]?.colStateProvince,
        "replaceOrHide"
      );
      setElementValue(
        document?.querySelector('footer [sectionid="ccCity"] .u-zip'),
        data?.tblRecords?.user?.contactDatas[0]?.colZipPostalCode,
        "replaceOrHide"
      );
    } else {
      setElementValue(
        document?.querySelector('footer [sectionid="ccCity"]'),
        data?.tblRecords?.user?.contactDatas[0]?.colCity,
        "replaceOrHide"
      );
    }

    setElementValue(
      document?.querySelector('footer [sectionid="ccState"]'),
      data?.tblRecords?.user?.contactDatas[0]?.colStateProvince,
      "replaceOrHide"
    );
    setElementValue(
      document?.querySelector('footer [sectionid="ccZipCode"]'),
      data?.tblRecords?.user?.contactDatas[0]?.colZipPostalCode,
      "replaceOrHide"
    );
    setElementValue(
      document?.querySelector('footer [sectionid="ccCountry"]'),
      data?.tblRecords?.user?.contactDatas[0]?.colCountry,
      "replaceOrHide"
    );
    if (Number(getTenantOrgId()) !== 18) {
      setElementValue(
        document?.querySelector('footer [sectionid="contact6"] a'),
        data?.tblRecords?.user?.contactDatas[0]?.bioEmail,
        "emailReplace"
      );
    }
    let footerEmailText = "";
    let getElement = document
      ?.querySelector("#IdFooter")
      ?.getElementsByTagName("a");
    if (getElement && getElement.length > 0) {
      for (let i = 0; i < getElement.length; i++) {
        if (
          getElement[i].getAttribute("href") === "mailto:CC-Email@" ||
          (Number(getTenantOrgId()) === 12 &&
            getElement[i]?.innerText === "CC-Email@")
        ) {
          footerEmailText = getElement[i];
          if (footerEmailText?.innerText === "CC-Email@") {
            setElementValue(
              footerEmailText,
              data?.tblRecords?.user?.contactDatas[0]?.bioEmail,
              "emailReplaceInnerText"
            );
          }
        }
      }
    }
  }

  if (Number(getTenantOrgId()) === 29) {
    if (data?.tblRecords?.user?.contactDatas[0]?.faxNum) {
      document.querySelector(
        ".social-lable.text-cen-mob.cc-fax"
      ).style.display = "";
    } else {
      document.querySelector(
        ".social-lable.text-cen-mob.cc-fax"
      ).style.display = "none";
    }
    setElementValue(
      document?.querySelector(".social-lable.text-cen-mob .c-info"),
      data?.tblRecords?.user?.contactDatas[0]?.faxNum,
      "replaceOrHide",
      null,
      !data?.tblRecords?.user?.contactDatas[0]?.faxNum
    );
  }

  if (Number(getTenantOrgId()) === 8 || Number(getTenantOrgId()) === 18) {
    if (
      document?.querySelector('[sectionid="contact7"] .dotitle.ellipsis')
        ?.children[1]
    ) {
      setElementValue(
        document.querySelector('[sectionid="contact7"] .dotitle.ellipsis')
          .children[1],
        data?.tblRecords?.user?.contactDatas[0]?.faxNum,
        null,
        null,
        !data?.tblRecords?.user?.contactDatas[0]?.faxNum
      );
    }
  } else if (
    !document.querySelector('[sectionid="contact7"] .DOdetailsHeading')
  ) {
    setElementValue(
      document?.querySelector('[sectionid="contact7"] .c-info') ||
        document?.querySelector(
          '[sectionid="contact7"]:not(.DOdetailsHeading)'
        ),
      data?.tblRecords?.user?.contactDatas[0]?.faxNum,
      null,
      null,
      !data?.tblRecords?.user?.contactDatas[0]?.faxNum
    );
  }
  if (
    !document.querySelector(
      'footer [sectionid="contact7"] .DOdetailsHeading'
    ) &&
    Number(getTenantOrgId()) === 12
  ) {
    setElementValue(
      document?.querySelector('footer [sectionid="contact7"] .c-info') ||
        document?.querySelector(
          'footer [sectionid="contact7"]:not(.DOdetailsHeading)'
        ),
      data?.tblRecords?.user?.contactDatas[0]?.faxNum,
      null,
      null,
      !data?.tblRecords?.user?.contactDatas[0]?.faxNum
    );
  }

  // Contact number details
  let arrayContact8 = document?.querySelectorAll(
    'footer [sectionid="contact8"]'
  );
  if (Number(getTenantOrgId()) === 67) {
    arrayContact8 = document?.querySelectorAll(
      'footer [sectionid="contact8"] span:first-child'
    );
  }
  if (
    Number(getTenantOrgId()) === 77 ||
    Number(getTenantOrgId()) === 29 ||
    (Number(getTenantOrgId()) === 12 &&
      (document?.querySelector("footer .version_2_2020") ||
        document?.querySelector("footer .sa-co-brand-2020")))
  ) {
    arrayContact8 = document?.querySelectorAll(
      'footer [sectionid="contact8"] span:last-child'
    );
  }
  if (getTenantOrgId() === "19") {
    // UVA Migration Fixes
    let elements = document?.querySelector('footer [sectionid="contact8"]');
    if (elements?.children?.length === 2) {
      elements.children[0].setAttribute("sectionid", "contact8");
      elements.children[0].classList.add("info-lable");
      elements.children[1].setAttribute("sectionid", "contact7");
      elements.removeAttribute("sectionid");
    } else {
      elements.classList.add("info-lable");
    }
    setElementValue(
      document?.querySelector('[sectionid="contact8"].info-lable'),
      data?.tblRecords?.user?.contactDatas[0]?.mobNum
        ? data?.tblRecords?.user?.contactDatas[0]?.mobNum
        : systemAdminInfo?.colContactNo,
      "replaceOrHide"
    );
    setElementValue(
      document?.querySelector('[sectionid="contact7"].info-lable'),
      data?.tblRecords?.user?.contactDatas[0]?.faxNum,
      "replaceOrHide",
      null,
      !data?.tblRecords?.user?.contactDatas[0]?.faxNum
    );
    // UVA Migration Fixes
    let checkContact = document?.querySelector('footer [sectionid="contact8"]');
    if (checkContact?.innerText && !checkContact.innerText.includes("Phone:")) {
      checkContact.parentElement.removeAttribute("style");
      checkContact.innerText = "Phone: " + checkContact?.innerText;
    }
    let checkFax = document?.querySelector('footer [sectionid="contact7"]');
    if (checkFax?.innerText && !checkFax.innerText.includes("Fax:")) {
      checkFax.parentElement.removeAttribute("style");
      checkFax.innerText = "Fax: " + checkFax.innerText;
    }
  } else {
    if (Number(getTenantOrgId()) === 77 || Number(getTenantOrgId()) === 29) {
      arrayContact8?.forEach((element) => {
        setElementValue(
          element,
          data?.tblRecords?.user?.contactDatas[0]?.mobNum
            ? data?.tblRecords?.user?.contactDatas[0]?.mobNum
            : systemAdminInfo?.colContactNo,
          "hideParent"
        );
      });
    } else {
      arrayContact8?.forEach((element) => {
        setElementValue(
          element,
          data?.tblRecords?.user?.contactDatas[0]?.mobNum
            ? data?.tblRecords?.user?.contactDatas[0]?.mobNum
            : systemAdminInfo?.colContactNo,
          "replaceOrHide"
        );
      });
    }
  }

  // Social Media icons
  setElementValue(
    document?.querySelector("footer #bioUpfacUrl"),
    data?.tblRecords?.user?.contactDatas[0]?.facUrl
      ? data?.tblRecords?.user?.contactDatas[0]?.facUrl
      : systemAdminInfo?.colFacebookUrl,
    "media"
  );
  setElementValue(
    document?.querySelector("footer #bioUplinkUrl"),
    data?.tblRecords?.user?.contactDatas[0]?.linkUrl
      ? data?.tblRecords?.user?.contactDatas[0]?.linkUrl
      : systemAdminInfo?.colLinkedinUrl,
    "media"
  );
  setElementValue(
    document?.querySelector("footer #bioUptwtUrl"),
    data?.tblRecords?.user?.contactDatas[0]?.twtUrl
      ? data?.tblRecords?.user?.contactDatas[0]?.twtUrl
      : systemAdminInfo?.colTwitterUrl,
    "media",
    true
  );
  setElementValue(
    document?.querySelector("footer #bioUpinstaUrl"),
    data?.tblRecords?.user?.contactDatas[0]?.colInstagramUrl
      ? data?.tblRecords?.user?.contactDatas[0]?.colInstagramUrl
      : systemAdminInfo?.colInstagramUrl,
    "media"
  );
  if (Number(getTenantOrgId()) === 18) {
    document?.querySelectorAll("footer #bioUpwebUrl").forEach((ele) => {
      if (ele.getAttribute("class").includes("web-site")) {
        setElementValue(
          ele.querySelector("a"),
          data?.tblRecords?.user?.contactDatas[0]?.webUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.webUrl
            : systemAdminInfo?.colWebAddress,
          "media"
        );
      } else if (
        ele.getAttribute("class").includes("circlesocial") ||
        ele.getAttribute("class").includes("social-rounded")
      ) {
        setElementValue(
          ele,
          data?.tblRecords?.user?.contactDatas[0]?.webUrl
            ? data?.tblRecords?.user?.contactDatas[0]?.webUrl
            : systemAdminInfo?.colWebAddress,
          "media"
        );
      }
    });
  } else {
    setElementValue(
      document?.querySelector("footer #bioUpwebUrl"),
      data?.tblRecords?.user?.contactDatas[0]?.webUrl
        ? data?.tblRecords?.user?.contactDatas[0]?.webUrl
        : systemAdminInfo?.colWebAddress,
      "media"
    );
    if (Number(getTenantOrgId()) === 27) {
      setElementValue(
        document?.querySelector("footer #bioUpwebUrlhref"),
        data?.tblRecords?.user?.contactDatas[0]?.webUrl
          ? data?.tblRecords?.user?.contactDatas[0]?.webUrl
          : systemAdminInfo?.colWebAddress,
        "media"
      );
    }
  }

  // Youtube
  if (document.querySelector("footer [onclick*=youtube]")) {
    const link = document.querySelector("footer [onclick*=youtube]");
    const onclickValue = link?.getAttribute("onclick");
    // Extract the URL from the onclick value using a regular expression
    const urlMatch = /window\.open\('([^']+)'/i.exec(onclickValue);
    const youtubeUrl = urlMatch ? urlMatch[1] : null;
    setElementValue(link, youtubeUrl, "media");
  }

  // flickr
  if (document.querySelector("footer [onclick*=flickr]")) {
    const link = document.querySelector("footer [onclick*=flickr]");
    const onclickValue = link?.getAttribute("onclick");
    // Extract the URL from the onclick value using a regular expression
    const urlMatch = /window\.open\('([^']+)'/i.exec(onclickValue);
    const flickrUrl = urlMatch ? urlMatch[1] : null;
    setElementValue(link, flickrUrl, "media");
  }
};

export const setupCommonInfo = (systemAdminInfo, recordInfo) => {
  document?.querySelector("#bioUpClassYear")?.style &&
    document.querySelector("#bioUpClassYear").removeAttribute("style");
  setElementValue(
    document?.querySelector("#bioUpClassYear"),
    recordInfo?.tblRecords?.user?.contactDatas[0]?.classYear
      ? " '" +
          recordInfo?.tblRecords?.user?.contactDatas[0]?.classYear.slice(-2)
      : "",
    "innerHTML"
  );
  setElementValue(
    document?.querySelector("span.copyrightYearReplacer"),
    systemAdminInfo?.colCopyrightYear,
    "innerHTML"
  );
  if (document?.querySelector('[sectionid="contact3"]')?.hasAttribute("style"))
    document?.querySelector('[sectionid="contact3"]').removeAttribute("style");
  if (Number(getTenantOrgId()) === 17) {
    setElementValue(
      document?.querySelector("div.GS_6.cc-name"),
      recordInfo?.tblRecords?.user?.contactDatas[0]?.colFullName,
      "innerHTML"
    );
    setElementValue(
      document?.querySelector("div .GS_6 .cc-title") ||
        document?.querySelector('[sectionid="contact3"]'),
      recordInfo?.tblRecords?.user?.contactDatas[0]?.bioTitle,
      "innerHTML"
    );
  } else if (Number(getTenantOrgId()) === 18) {
    setElementValue(
      document?.querySelector('[sectionid="contact3"] .cc-title1') ||
        document?.querySelector('[sectionid="contact3"] .cctitle') ||
        document?.querySelector('[sectionid="contact3"].cc-title'),
      recordInfo?.tblRecords?.user?.contactDatas[0]?.bioTitle,
      "innerHTML"
    );
  } else if (Number(getTenantOrgId()) === 8) {
    setElementValue(
      document?.querySelector("div.greetingLine6.GS_6.CC-TITLE"),
      recordInfo?.tblRecords?.user?.contactDatas[0]?.bioTitle,
      "innerHTML"
    );
  } else {
    setElementValue(
      document?.querySelector("div.greetingLine6.GS_6") ||
        document?.querySelector("div.GS_6") ||
        document?.querySelector('[sectionid="contact3"]') ||
        document?.querySelector('[sectionid="contact3"] .cc-title1'),
      recordInfo?.tblRecords?.user?.contactDatas[0]?.bioTitle,
      "innerHTML"
    );
  }
  if (Number(getTenantOrgId()) === 8) {
    setElementValue(
      document?.querySelector("div.GS_4.CC-NAME"),
      recordInfo?.tblRecords?.user?.colFullName,
      "innerHTML"
    );
  } else {
    setElementValue(
      document?.querySelector("div.GS_4 .ccnamereplace") ||
        document?.querySelector("div.GS_4") ||
        document?.querySelector("span.GS_4") ||
        document?.querySelector("span.cc-name") ||
        document?.querySelector("span.ccnamereplace"),
      recordInfo?.tblRecords?.user?.colFullName,
      "innerHTML"
    );
  }
  if (document?.querySelector(".prefix-replacer")) {
    if (
      recordInfo?.tblRecords?.user?.contactDatas[0]?.bioPrefix
        ?.trim()
        ?.replace("None", "")
    ) {
      setElementValue(
        document?.querySelector(".prefix-replacer"),
        recordInfo?.tblRecords?.user?.contactDatas[0]?.bioPrefix + " ",
        "innerHTML"
      );
    } else if (document?.querySelector(".prefix-replacer")) {
      document.querySelector(".prefix-replacer").style.display = "none";
    }
  }
  if (document?.querySelector(".suffix-replacer")) {
    if (
      recordInfo?.tblRecords?.user?.contactDatas[0]?.bioSuffix
        ?.trim()
        ?.replace("None", "")
    ) {
      if (
        document
          .getElementsByTagName("body")[0]
          .classList.contains("REBRAND_2024_BODY") &&
        Number(getTenantOrgId()) === 17
      ) {
        setElementValue(
          document?.querySelector(".suffix-replacer"),
          " " + recordInfo?.tblRecords?.user?.contactDatas[0]?.bioSuffix,
          "innerHTML"
        );
      } else if (Number(getTenantOrgId()) === 90) {
        setElementValue(
          document?.querySelector(".suffix-replacer"),
          " " + recordInfo?.tblRecords?.user?.contactDatas[0]?.bioSuffix,
          "innerHTML"
        );
      } else if (
        document
          .getElementsByTagName("body")[0]
          .classList.contains("REBRAND_2024_BODY") && (getTenantOrgId() === "36" || getTenantOrgId() === "69")
         
      ) {
        setElementValue(
          document?.querySelector(".suffix-replacer"),
          " " + recordInfo?.tblRecords?.user?.contactDatas[0]?.bioSuffix,
          "innerHTML"
        );
      } else {
        setElementValue(
          document?.querySelector(".suffix-replacer"),
          ", " + recordInfo?.tblRecords?.user?.contactDatas[0]?.bioSuffix,
          "innerHTML"
        );
      }
    } else if (document?.querySelector(".suffix-replacer")) {
      document.querySelector(".suffix-replacer").style.display = "none";
    }
  }
};

export const setElementValue = (
  element,
  value,
  flag,
  isTwitter,
  hideParent,
  oldUrl
) => {
  if (flag && element) {
    if (flag === "src") {
      value
        ? element.setAttribute("src", value)
        : (element.style.display = "none");
      if (Number(getTenantOrgId()) === 7 || Number(getTenantOrgId()) === 17) {
        if (value && element?.parentNode?.style?.display === "none") {
          element.parentNode.style.display = "block";
        } else if (
          (element?.parentNode?.style?.display === "block" ||
            element?.style?.display === "none") &&
          !value
        ) {
          element.parentNode.style.display = "none";
        }
      }
    }
    if (flag === "greetingSrc") {
      if (value) {
        element.parentElement.removeAttribute("style");
        element.setAttribute("src", value);
      } else {
        element.parentElement.style.display = "none";
        element.setAttribute(
          "src",
          "https://asset.common.ovrture.com/common/default-contact-image.jpeg"
        );
      }
    }
    if (flag === "href")
      value
        ? element.setAttribute("href", value)
        : (element.style.display = "none");
    if (flag === "media") {
      element.removeAttribute("onclick", value);
      element.removeAttribute("href");
      if (value) {
        element.setAttribute(
          "target_href",
          isTwitter ? "https://twitter.com/" + value : value
        );
        if (innerHtmlForTenantSpecific(oldUrl)) {
          element.innerText = value;
        }
        element?.addEventListener("click", (e) => {
          let targetHref = element.getAttribute("target_href");
          if (!targetHref.startsWith("http"))
            targetHref = "https://" + targetHref;
          window.open(targetHref, "_blank");
        });
        if (
          Number(getTenantOrgId()) === 8 ||
          Number(getTenantOrgId()) === 12 ||
          (Number(getTenantOrgId()) === 7 &&
            !document?.body?.classList?.contains("palette20"))
        ) {
          setTimeout(() => (element.style.display = "inline"), 1000);
        } else setTimeout(() => (element.style.display = "inline-block"), 1000);
      } else {
        if (
          Number(getTenantOrgId()) === 7 &&
          !document?.body?.classList?.contains("palette20")
        ) {
          element.parentElement.parentElement.style.display = "none";
        } else {
          element.style.display = "none";
        }
        innerHtmlForTenantSpecificHidden(oldUrl, element);
      }

      if (
        Number(getTenantOrgId()) === 8 ||
        (Number(getTenantOrgId()) === 1 &&
          !document?.querySelector("body.REBRAND_2024_BODY")) ||
        (Number(getTenantOrgId()) === 17 && element.getAttribute("title"))
      ) {
        let targetHref = element.getAttribute("target_href");
        if (!targetHref?.startsWith("http"))
          targetHref = "https://" + targetHref;
        if (!element.classList.contains("ui-link"))
          element.innerText = targetHref;
      } else if (
        (Number(getTenantOrgId()) === 18 &&
          element.getAttribute("title") === "bioUpwebUrlhref") ||
        (Number(getTenantOrgId()) === 7 &&
          !document?.body?.classList?.contains("palette20"))
      ) {
        let targetHref = element.getAttribute("target_href");
        if (!targetHref?.startsWith("http"))
          targetHref = "https://" + targetHref;
        element.innerText = targetHref;
      }
    }
    if (flag === "replaceOrHideChild")
      value ? (element.innerHTML = value) : (element.style.display = "none");
    if (flag === "replaceOrHide")
      value ? (element.innerHTML = value) : (element.style.display = "none");
    if (flag === "emailReplace") {
      if (value) {
        element.href = "mailto:" + value;
        if (element?.querySelector("span")) {
          element.querySelector("span").innerHTML = value;
        }
      } else {
        element.style.display = "none";
      }
    }
    if (flag === "emailReplaceInnerText") {
      if (value) {
        element.href = "mailto:" + value;
        element.innerHTML = value;
      } else {
        element.style.display = "none";
      }
    }
    if (flag === "innerHTML") {
      element?.parentElement?.removeAttribute("style");
      if (value) {
        element.innerHTML = value;
      } else {
        element.style.display = "none";
        window?.eventHandlerTitleHide && window.eventHandlerTitleHide(element);
      }
    }
    if (flag === "hideParent") {
      element?.parentElement?.removeAttribute("style");
      if (value) {
        element.innerHTML = value;
      } else {
        element.parentElement.style.display = "none";
        window?.eventHandlerTitleHide && window.eventHandlerTitleHide(element);
      }
    }
    if (flag === "style") element.setAttribute("style", value);
  } else if (element) {
    element.innerText = value;
    if (hideParent) element.parentElement.style.display = "none";
  }
};

const innerHtmlForTenantSpecific = (oldUrl) => {
  if (oldUrl && Number(getTenantOrgId()) === 16) {
    return true;
  } else if (
    oldUrl &&
    Number(getTenantOrgId()) === 12 &&
    document?.querySelector("body")?.classList?.length === 0
  ) {
    return true;
  } else {
    return false;
  }
};

const innerHtmlForTenantSpecificHidden = (oldUrl, element) => {
  if (
    oldUrl &&
    Number(getTenantOrgId()) === 12 &&
    document?.querySelector("body")?.classList?.length === 0
  ) {
    element.closest(".doheading").style.display = "none";
  }
};

export const scriptTagHandler = () => {
  // let scriptArray = [
  //   `${process.env.REACT_APP_S3_URL}/assets/js/common.js`,
  //   `${process.env.REACT_APP_S3_URL}/assets/js/output.js`,
  //   `https://www.gstatic.com/charts/loader.js`,
  // ];
  const folderName = process.env.REACT_APP_TENANT.split("_").pop();
  let scriptArray = [
    `/${folderName}/static/js/tenant_lib.min.js`,
    `/${folderName}/static/js/common.js`,
    `/${folderName}/static/js/output.js`,
    `https://www.gstatic.com/charts/loader.js`,
  ];
  if (process.env.REACT_APP_ACCESS_LOCAL_FILE === "true") {
    scriptArray = [
      `./assets/js/tenant_lib.min.js`,
      `./assets/js/common.js`,
      `./assets/js/output.js`,
      `https://www.gstatic.com/charts/loader.js`,
    ];
  }
  ScriptHandler(scriptArray);
};

export const getHtmlString = (htmlData) => {
  let doc = new DOMParser().parseFromString(htmlData, "text/html");
  let newDoc = document.querySelector("#rootHtml #page-container");
  doc.getElementById("page-container")?.replaceWith(newDoc);
  return doc.querySelector("html")?.outerHTML;
};
export const disable_ele_items = (ele, left_cond, right_cond, del_cond) => {
  left_cond
    ? $(ele)
        .find(".move-update-left")
        .addClass("pwdon0")
        .parent()
        .addClass("disabledBackground")
    : $(ele)
        .find(".move-update-left")
        .removeClass("pwdon0")
        .parent()
        .removeClass("disabledBackground");

  right_cond
    ? $(ele)
        .find(".move-update-right")
        .addClass("pwdon0")
        .parent()
        .addClass("disabledBackground")
    : $(ele)
        .find(".move-update-right")
        .removeClass("pwdon0")
        .parent()
        .removeClass("disabledBackground");

  del_cond
    ? $(ele)
        .find(".delete-report-update")
        .addClass("pwdon0")
        .parent()
        .addClass("disabledBackground")
    : $(ele)
        .find(".delete-report-update")
        .removeClass("pwdon0")
        .parent()
        .removeClass("disabledBackground");
};
export const updateOperationBarButtonED = () => {
  if ($(".report-updates-tab").length > 0) {
    var total_updates = $(".report-updates-tab .panelDiv").length;
    $(".report-updates-tab .panelDiv")
      .find(".move-update-left")
      .removeClass("pwdon0")
      .parent()
      .removeClass("disabledBackground");
    $(".report-updates-tab .panelDiv")
      .find(".move-update-right")
      .removeClass("pwdon0")
      .parent()
      .removeClass("disabledBackground");
    $(".report-updates-tab .panelDiv")
      .find(".delete-report-update")
      .removeClass("pwdon0")
      .parent()
      .removeClass("disabledBackground");
    var first_ele = $(".report-updates-tab .panelDiv")[0];
    //switch case based on no of updates
    switch (total_updates) {
      case 1:
        disable_ele_items(first_ele, true, true, true);
        break;
      case 2:
        disable_ele_items(first_ele, true, false, false);
        var last_ele = $(".report-updates-tab .panelDiv")[total_updates - 1];
        disable_ele_items(last_ele, false, true, false);
        break;
      default:
        disable_ele_items(first_ele, true, false, false);
        var last_ele = $(".report-updates-tab .panelDiv")[total_updates - 1];
        disable_ele_items(last_ele, false, true, false);
    }
    $(".report-updates-tab ul li.active .panelDiv")
      .find(".delete-report-update")
      .addClass("pwdon0")
      .parent()
      .addClass("disabledBackground");
  }
};

/* Purpose: To hide select fund option in case of single fund [common js] */
//Example: hideSelectFundLib(["#FUND_DESCRIPTION","#FUND_SOA","#FUND_PERFORMANCE","#FUND_IMPACT","#FUND_PERFORMANCE_DISTRIBUTION","#FUND_PERFORMANCE_ENDOWMENT"])
export const hideSelectFundLib = (panelArr) => {
  try {
    if (panelArr.length >= 0) {
      var i = 0;
      $.each(panelArr, function () {
        if ($(panelArr[i] + " .carousel .carousel-inner .item").length === 1) {
          $(
            panelArr[i] +
              " .carousel .report-carousel-indicators .fund-footer-wrapper"
          ).hide();
        }
        i++;
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const swapNode = (nodeA, nodeB) => {
  const parentA = nodeA.parentNode;
  const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

  // Move `nodeA` to before the `nodeB`
  nodeB.parentNode.insertBefore(nodeA, nodeB);

  // Move `nodeB` to before the sibling of `nodeA`
  parentA.insertBefore(nodeB, siblingA);
};

export const editorNavUpdate = () => {
  const path = window.location.pathname;
  return !(path.includes("/site-edit") || path.includes("/report-edit"));
};

/******* FUNCTIONS CALLED IN MULTIPLE ACTIONS ON EDITORS/OUTPUT *******/
// Functions which is called in multiple actions --> editor, output, add panel, shift panels
export const addContentPanelJSInitilization = () => {
  // if (!customYearRendering.includes(getTenantOrgId())) {
  //   util.year_rendering();
  // }
  util.year_rendering();
  StanadrdPanel.StdAutoAlteringBackgroudColor();
  if (!notRequiredAmountConversion?.includes(Number(getTenantOrgId()))) {
    util.amount_conversion(".LIFETIME_GIVING .lifeTimeGivingAmountOfRecord");
    util.amount_conversion(".FY_GIVING .fyGivingAmountOfRecord");
    util.amount_conversion(".FY_GIFT .fyLastGiftAmountOfRecord");
    util.amount_conversion(".FY_CURRENT_USE .fyCurrentUseAmountOfRecord");
  }
  if (Number(getTenantOrgId()) !== 19) {
    util.removeInitialCharacter(
      ".LIFETIME_GIVING .lifeTimeGivingDateOfRecord",
      6
    );
    util.removeInitialCharacter(
      ".LIFETIME_GIVING_PRIMARY .lifeTimeGivingDateOfRecord",
      6
    );
  }
  util.removeInitialCharacter(".FY_GIVING .fyGivingDateOfRecord", 6);
  util.removeInitialCharacter(".FY_GIVING_PRIMARY .fyGivingDateOfRecord", 6);
  if (getTenantOrgId() === "45") {
    util.removeInitialCharacter(".FY_GIFT .fyLastGiftDateOfRecord", 12);
  } else {
    util.removeInitialCharacter(".FY_GIFT .fyLastGiftDateOfRecord", 6);
  }
  util.removeInitialCharacter(".FY_CURRENT_USE .fyCurrentUseDateOfRecord", 6);
  util.removeInitialCharacter(".YEARS_GIVING .fyGivingDateOfRecord", 6);
  if (getTenantOrgId() === "70" || getTenantOrgId() === "42") {
    util.removeInitialCharacter(
      ".YEARS_GIVING_PARENT .fyGivingDateOfRecord",
      6
    );
  }
  if (showCarouselIndicatorNumber.includes(Number(getTenantOrgId()))) {
    if (Number(getTenantOrgId()) === 1) {
      slideNumber(".REBRAND_2024");
    } else {
      slideNumber("");
    }
  }
};

export const deleteContentPanelJSInitilization = () => {
  StanadrdPanel.StdAutoAlteringBackgroudColor();
};

export const upDownContentPanelJSInitilization = () => {
  StanadrdPanel.StdAutoAlteringBackgroudColor();
};

var util = {
  // Report Year rendering
  year_rendering: function () {
    if (type === "report") {
      var fY = $("#fiscalYear").val();
      var currentYr = parseInt(fY);
      var previousYear = currentYr - 1;
      if (
        fY &&
        ($("#FY_GIVING").length > 0 ||
          $("#FY_CURRENT_USE").length > 0 ||
          $("#FY_GIVING_PRIMARY").length > 0)
      ) {
        var last2 = fY?.slice(-2);
        var newStr = previousYear + " - " + last2;
        if (
          customPanelYearReplacerForReport?.includes(Number(getTenantOrgId()))
        ) {
          $("#FY_GIVING .year, #FY_CURRENT_USE .year").html(fY);
        } else if (Number(getTenantOrgId()) === 34) {
          $(".year").html(newStr);
        } else {
          $(".year").html(fY);
        }
      }
    } else {
      if (Number(getTenantOrgId()) === 68 || Number(getTenantOrgId()) === 45) {
        $("#FY_GIVING .year, #FY_CURRENT_USE .year").html("{FY}");
      } else if (
        customPanelYearReplacerForSite?.includes(Number(getTenantOrgId()))
      ) {
        $("#FY_GIVING .year, #FY_CURRENT_USE .year").html("FY");
      } else {
        $(".year").html("FY");
      }
    }
  },
  // Description : For Lifetime and Fy Giving Panel - if value not exist place
  amount_conversion: function (selected_class) {
    if ($(selected_class)[0]) {
      /* EXISTS (not undefined) */
      var element = $(selected_class).html().trim();
      if (element != "$X,XXX,XXX") {
        var actual_value = element.replace(/[$,]/g, "");
        var value = parseFloat(actual_value).toFixed(2);
        if (isNaN(value)) {
          var value = "X,XXX,XXX";
        }
        var n = value.toString().split(".");
        n[0] = n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var final_value = n.join(".");
        $(selected_class).html("$" + final_value);
      }
    }
  },
  // Description : For Lifetime and Fy Giving Panel - remove intial character
  removeInitialCharacter: function (stringClass, removeCharacterCount) {
    if ($(stringClass)[0]) {
      /* EXISTS (not undefined) */
      var element = $(stringClass).html().trim();
      if ("Total" === element.substring(0, 5)) {
        element = element.substring(removeCharacterCount);
        if (element && Number(getTenantOrgId()) == 58) {
          let parts = element.split(" ");
          if (parts.length === 3 && parts[0] === "as" && parts[1] === "of") {
            let dateParts = parts[2].split("/");
            if (dateParts.length === 3) {
              let year = dateParts[2].substring(2);
              parts[2] = `${dateParts[0]}/${dateParts[1]}/${year}`;
              let string = parts.join(" ");
              $(stringClass).html(string);
            }
          } else {
            let dateParts = parts[1].split("/");
            if (dateParts.length === 3) {
              let year = dateParts[2].substring(2);
              parts[1] = `${dateParts[0]}/${dateParts[1]}/${year}`;
              let string = parts.join(" ");
              $(stringClass).html(string);
            }
          }
        } else if (element && Number(getTenantOrgId()) == 29) {
          element = element.charAt(0).toUpperCase() + element.slice(1);
          $(stringClass).html(element);
        } else {
          $(stringClass).html(element);
        }
      }
    } else {
    }
  },
};

var StanadrdPanel = {
  // REMOVE ALL CLASSES RELATED TO bgColor FROM ALL STANDARD PANEL
  removeAllBbColor: function () {
    $(".standard-template").each(function (i) {
      var match = $(this)
        .attr("class")
        .match(/\bbg\S+/g);
      if (match != null) {
        var className = match;
        $(this).removeClass(className.toString());
      }
    });
  },
  //ADD bgColor CLASS IN ALL STANDARD PANEL
  StdAutoAlteringBackgroudColor: function () {
    //CALL FUNCTION TO REMOVE bgColor CLASS
    StanadrdPanel.removeAllBbColor();
    $(".standard-template").each(function (i) {
      $(this).addClass("bgColor" + i);
    });
  },
};

export const fundPanelSequenceDetail = (skipRemoveRDTPanel) => {
  const activeTab = document?.querySelector(".report-update-data.active");
  const fundPanelSequenceDetails = activeTab
    ? activeTab?.getAttribute("data-fund_panel_seq")
    : document?.querySelectorAll("#fundPanelSequenceDetail");

  if (!activeTab && fundPanelSequenceDetails?.length > 1)
    for (let index = 1; index < fundPanelSequenceDetails.length; index++)
      fundPanelSequenceDetails[index].remove();

  let fundPanelSequenceDetail = {
    fund_description: "",
    fund_performance_distribution: "",
    fund_performance_endowment: "",
    fund_soa: "",
    fund_performance: "",
    fund_impact: "",
  };
  const list = document.querySelectorAll(".slideSecClass");
  let FUND_DESCRIPTION = null;
  let FUND_SOA = null;
  let FUND_PERFORMANCE = null;
  let FUND_PERFORMANCE_DISTRIBUTION = null;
  let FUND_PERFORMANCE_ENDOWMENT = null;
  let FUND_IMPACT = null;
  for (let index = 0; index < list.length; index++) {
    let indexdata = findIndexDom(list[index], index, "#FUND_DESCRIPTION");
    if (
      indexdata !== null &&
      indexdata !== undefined &&
      FUND_DESCRIPTION === null
    ) {
      FUND_DESCRIPTION = indexdata;
    }
    indexdata = findIndexDom(list[index], index, "#FUND_SOA");
    if (indexdata !== null && indexdata !== undefined && FUND_SOA === null) {
      FUND_SOA = indexdata;
    }
    indexdata = findIndexDom(list[index], index, "#FUND_PERFORMANCE");
    if (
      indexdata !== null &&
      indexdata !== undefined &&
      FUND_PERFORMANCE === null
    ) {
      FUND_PERFORMANCE = indexdata;
    }
    indexdata = findIndexDom(
      list[index],
      index,
      "#FUND_PERFORMANCE_DISTRIBUTION"
    );
    if (
      indexdata !== null &&
      indexdata !== undefined &&
      FUND_PERFORMANCE_DISTRIBUTION === null
    ) {
      FUND_PERFORMANCE_DISTRIBUTION = indexdata;
    }
    indexdata = findIndexDom(list[index], index, "#FUND_PERFORMANCE_ENDOWMENT");
    if (
      indexdata !== null &&
      indexdata !== undefined &&
      FUND_PERFORMANCE_ENDOWMENT === null
    ) {
      FUND_PERFORMANCE_ENDOWMENT = indexdata;
    }
    indexdata = findIndexDom(list[index], index, "#FUND_IMPACT");
    if (indexdata !== null && indexdata !== undefined && FUND_IMPACT === null) {
      FUND_IMPACT = indexdata;
    }
  }
  setFundPanelSequence(
    FUND_DESCRIPTION,
    fundPanelSequenceDetail,
    "fund_description"
  );
  setFundPanelSequence(
    FUND_PERFORMANCE_DISTRIBUTION,
    fundPanelSequenceDetail,
    "fund_performance_distribution"
  );
  setFundPanelSequence(
    FUND_PERFORMANCE_ENDOWMENT,
    fundPanelSequenceDetail,
    "fund_performance_endowment"
  );
  setFundPanelSequence(FUND_SOA, fundPanelSequenceDetail, "fund_soa");
  setFundPanelSequence(
    FUND_PERFORMANCE,
    fundPanelSequenceDetail,
    "fund_performance"
  );
  setFundPanelSequence(FUND_IMPACT, fundPanelSequenceDetail, "fund_impact");
  if (activeTab) {
    activeTab.setAttribute(
      "data-fund_panel_seq",
      JSON.stringify(fundPanelSequenceDetail)
    );
  } else {
    fundPanelSequenceDetails[0].textContent = JSON.stringify(
      fundPanelSequenceDetail
    );
  }
  if (!skipRemoveRDTPanel) {
    document?.querySelectorAll(".slideSecClass")?.forEach((element) => {
      if (
        element?.querySelectorAll(".report-cp-panel")?.length > 0 ||
        element.querySelector("div") === null
      ) {
        element?.remove();
      }
    });
  }
  //Update slide id
  let array = document?.querySelectorAll(".slideSecClass");
  for (let index = 0; index < array.length; index++)
    array[index].id = "slides_" + (index + 1);
};

const setFundPanelSequence = (panel, obj, fund) => {
  if (panel !== null && panel !== undefined) obj[fund] = panel;
  else delete obj[fund];
};

const findIndexDom = (element, index, selector) => {
  if (element.querySelectorAll(selector)?.length > 0) return index;
  else return null;
};

export const removeStdLastItemSlide = () => {
  let items = document.querySelectorAll(".item.lastItem");
  for (let index = 0; index < items.length; index++) {
    items[index].remove();
  }
};

export const chartgraphs = (idName, divClassName) => {};

const performheightBasedAdjustment = (panelNameID, elementArrayPath) => {
  if (document.querySelectorAll(panelNameID).length > 0) {
    // grab all slides
    var panelItems = document.querySelectorAll(
      panelNameID + " .carousel-inner .item"
    );
    // create empty array to store height values
    var heights = [];
    // create variable to make note of the tallest slide
    var tallest;

    var panelElement = document.querySelectorAll(
      panelNameID +
        " .carousel-inner " +
        elementArrayPath +
        " > div:nth-child(2)"
    );
    panelElement.forEach(function (item) {
      item.setAttribute("style", "height:auto");
      [...item.children].forEach(function (itemChild) {
        itemChild.setAttribute("style", "height:auto");
      });
    });

    if (window.innerWidth > 1199) {
      document
        .querySelector(panelNameID + " .contentpanelname")
        .setAttribute("style", "padding-bottom : 50px");
    } else {
      document
        .querySelector(panelNameID + " .contentpanelname")
        .setAttribute("style", "padding-bottom : 41.3px");
    }

    if (panelItems.length > 0) {
      panelItems[0].classList.remove("active");
      panelItems.forEach(function (item, index) {
        //add heights to array
        panelItems[index].classList.add("active");
        heights.push(item.clientHeight);
        panelItems[index].classList.remove("active");
      });
      panelItems[0].classList.add("active");
      tallest = Math.max.apply(null, heights); //cache largest value
      document
        .querySelector(panelNameID + " .carousel-inner")
        .setAttribute("style", "height : " + tallest + "px");
      if (document.querySelectorAll(".NCSU_IMPACT").length > 0) {
        document
          .querySelector(panelNameID + " .fund-outer-Wrapper")
          .setAttribute("style", "height : " + tallest + "px");
      }
    } else {
      document
        .querySelector(panelNameID + " .contentpanelname")
        .setAttribute("style", "padding-bottom : 0px");
      document
        .querySelector(panelNameID + " .carousel-inner")
        .setAttribute("style", "height : auto");
    }
  }
};

// Change Facebook Icon to Facebook Perofile Link // renderFbIconAsText

export const renderSocialIconAsTextOnRdtPanel = () => {
  try {
    if (renderSocialIconAsText?.includes(Number(getTenantOrgId()))) {
      var socialIconValue = document?.querySelectorAll(
        ".report-cp-panel .link-expand .value"
      );
      for (var m = 0; m < socialIconValue?.length; m++) {
        var link = socialIconValue[m]?.getAttribute("href");
        if (link?.includes("mailto:")) {
          link = link.replace("mailto:", "");
        }
        socialIconValue[m].innerHTML = link;
      }
    }
  } catch {
    console.log("error");
  }
};

export const RdtLargestPanelHeight = () => {
  // Fund Impact Height
  setTimeout(() => {
    performheightBasedAdjustment("#FUND_IMPACT", ".fund-impact-elements");
    performheightBasedAdjustment(
      "#FUND_DESCRIPTION",
      ".fundDescriptionElement"
    );
    if (getTenantOrgId() === "74") {
      performheightBasedAdjustment("#FUND_SOA", ".fundSoaElement");
    } else {
      performheightBasedAdjustment("#FUND_SOA", ".fundSOAElement");
    }
  }, 1000);

  // Max-height setting on preview modal
  var previewModal = document.querySelector(".preview .previewOnly");
  if (previewModal) {
    $("#FUND_IMPACT").css({ "max-height": "auto", "overflow-y": "scroll" });
  }
};

export const reportUpdateSetTitle = () => {
  var reportUpdateTabLenght = $("a.report-update-name").length;
  if (reportUpdateTabLenght > 1) {
    for (var i = 0; i < reportUpdateTabLenght; i++) {
      var reportUpdatesLI = $("a.report-update-name")[i];
      var reportUpdatesLIText = reportUpdatesLI.text.trim();
      reportUpdatesLI.parentElement.setAttribute("title", reportUpdatesLIText);
      reportUpdatesLI.classList.add("ellipses");
    }
  } else {
    return;
  }
};

export const InlineSafeSecurity = () => {
  try {
    const urlRegex = /(https?:\/\/[^ &]*)/;
    const backgroundGreetingImage = document
      ?.querySelector(".texture-overlay-wrapper")
      ?.outerHTML?.match(urlRegex)[1];
    document.querySelector(
      ".texture-overlay-wrapper"
    ).style.backgroundImage = `url(${backgroundGreetingImage})`;
  } catch (ex) {}
};

export const DecodeJwtToken = (token) => {
  try {
    return jwt(token);
  } catch (ex) {}
};

export const reportUpdateTabHide = () => {
  const updateTab = document.querySelectorAll(".report-updates-tab ul li");
  if (updateTab.length <= 1) {
    document.querySelector(".report-updates-tab").classList.add("hide");
  }
};
export const removeTinymceEditor = () => {
  document?.querySelectorAll('[contenteditable="true"]').forEach((element) => {
    element.removeAttribute("contenteditable");
  });
};
//replace panel header value of panel
export const panelHeaderReplace = () => {
  // For SOA and Impact Year Replacer
  var currentYear = $(
    ".fund-header .FPERF-Report-Year, .fund-header .FIMPACT-Report-Year"
  )
    .first()
    .text();
  var previousYear = $(
    ".fund-header .FPERF-Report-Year-1, .fund-header .FIMPACT-Report-Year-1"
  )
    .first()
    .text();
  $(
    ".slider-mobile-view-hearder .FPERF-Report-Year, .slider-mobile-view-hearder .FIMPACT-Report-Year"
  ).text(currentYear);
  $(
    ".slider-mobile-view-hearder .FPERF-Report-Year-1, .slider-mobile-view-hearder .FIMPACT-Report-Year-1"
  ).text(previousYear);

  var updatedDate = $(".fund-header .FDetails_Fund_Updated_date")
    .first()
    .text();
  $(".slider-mobile-view-hearder .FDetails_Fund_Updated_date").text(
    updatedDate
  );
};

export const fixedHeader = (orgId) => {
  if (orgId === 54) {
    var broadcastHeightValue = document?.querySelector(
      ".broadCastTemplpatesWrapper"
    )?.offsetHeight;
    var headerBanner = document.querySelector("header").offsetHeight;
    if (broadcastHeightValue === undefined) broadcastHeightValue = null;
    if (window.pageYOffset >= broadcastHeightValue) {
      document.querySelector(
        ".main-body-container"
      ).style.cssText = `margin-top : ${headerBanner}px`;
      document.querySelector("header").classList.add("navbar-fixed-top");
      document.querySelector("header").style.cssText = `margin-top:0;`;
    } else {
      document.querySelector("header").classList.remove("navbar-fixed-top");
      document.querySelector(
        ".main-body-container"
      ).style.cssText = `margin-top:0;`;
    }
  }
};

export const updateBodyTag = (htmlData) => {
  let doc = new DOMParser().parseFromString(htmlData, "text/html");
  document.querySelector("body").id = doc.querySelector("body").id;
  document.querySelector("body").classList =
    doc.querySelector("body").classList;
  if (doc?.querySelector(".assetReplacerClassForFundDescription")) {
    doc.querySelector(".assetReplacerClassForFundDescription").style.display =
      "none";
  }
};

export const copyRightYearReplacementOldTenant = () => {
  if (copyRightYearOldTenantReplacer?.includes(getTenantOrgId())) {
    let textContent = document?.querySelector(
      ".copyright-wrapper span"
    )?.innerText;
    if (!textContent) {
      textContent = document?.querySelector(".mmcopyParent span")?.innerText;
      if (
        Number(getTenantOrgId()) === 18 &&
        document?.querySelector(".REBRAND_2021")
      ) {
        textContent = document?.querySelector(
          ".footer-copyright span"
        )?.innerText;
      }
    }
    let backendYear = document.getElementById("copyRightYear")?.value;
    const textContentArray = textContent?.split(" ");
    let year = "";
    for (let index = 0; index < textContentArray?.length; index++) {
      if (year) break;
      const element = textContentArray[index];
      if (isNumber(element)) {
        year = element.trim();
      }
    }
    if (year && year !== backendYear) {
      const newContent = textContent.replace(year, backendYear);
      if (document?.querySelector(".copyright-wrapper span")?.innerText) {
        document.querySelector(".copyright-wrapper span").innerText =
          newContent;
      } else {
        document.querySelector(".mmcopyParent span").innerText = newContent;
      }
    }
  }
};

function isNumber(value) {
  if (typeof value === "string") {
    return !isNaN(value);
  }
}

export const collapsedOnMobileView = () => {
  const reportUpdatesTabLi = document.querySelectorAll(
    ".report-updates-tab li"
  );
  if (window.innerWidth < 992 && reportUpdatesTabLi.length > 1) {
    for (let index = 1; index < reportUpdatesTabLi.length; index++) {
      const element = reportUpdatesTabLi[index];
      element.style.display = "none";
    }
  }
};

export function changeCoverImageOnPageLoad() {
  try {
    if (
      window.location.pathname.includes("report-edit") ||
      window.location.pathname.includes("site-edit") ||
      window.location.pathname.includes("sites") ||
      window.location.href.includes("reports")
    ) {
      let classname = localStorage.getItem("classname");
      let obj = JSON.parse(localStorage.getItem("editorStorageKey"));
      if (obj && obj["coverImgInfo"]) {
        classname = obj["coverImgInfo"]["coverImgClassName"];
      }
      let inputImg = document.getElementsByClassName(
        "greetingPanelBackgroundImage"
      );
      if (inputImg && inputImg?.length > 0 && classname) {
        const urlVal = inputImg[0]?.getAttribute("data-bg-url");
        const coverImgDiv = document.getElementsByClassName(classname);
        if (urlVal && coverImgDiv) {
          if (coverImgDiv?.length > 0) {
            coverImgDiv[0].style.backgroundImage = `url(${urlVal})`;
          }
          if (
            document?.querySelector(
              ".greetingPanelparent .mobile-view-only img"
            )
          ) {
            document
              .querySelector(".greetingPanelparent .mobile-view-only img")
              .setAttribute("src", urlVal);
          } else if (document?.querySelector(".mob-intro-img img")) {
            document
              .querySelector(".mob-intro-img img")
              .setAttribute("src", urlVal);
          } else if (document?.querySelector(".intro-panel .intro-image img"))
            urlVal &&
              document
                .querySelector(".intro-panel .intro-image img")
                .setAttribute("src", urlVal);
          else if (
            document?.querySelector(".intro-panel .mobile-cover-image img")
          )
            urlVal &&
              document
                .querySelector(".intro-panel .mobile-cover-image img")
                .setAttribute("src", urlVal);
          else if (
            Number(getTenantOrgId()) === 16 &&
            !document?.querySelector("body.REBRAND_2021_BODY")
          )
            urlVal &&
              coverImgDiv[0].querySelector("img").setAttribute("src", img);

          if (Number(getTenantOrgId()) !== 18)
            if (inputImg.length > 0) inputImg[0].remove();
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

let s3AssetUrls = [
  "https://dev-v1-a4f2200055ce0c6e2032d4ed41c6c25e.s3.amazonaws.com/",
  "https://asset.dev.ovrture.com/",
  "https://test-azctl-assets.s3.amazonaws.com/",
  "https://asset.test.ovrture.com/",
  "https://staging-xzbrv-assets.s3.amazonaws.com/",
  "https://asset.staging.ovrture.com/",
  "https://shared-production-ifwvu-assets.s3.us-east-1.amazonaws.com/",
  "https://asset.production.ovrture.com/",
  "https://canada-assets-74d70c4867aa01d364945b7bd1bc779567872bcc.s3.ca-central-1.amazonaws.com/",
  "https://asset-canada.production.ovrture.com/",
  "https://ovrture-testing.s3.us-west-2.amazonaws.com/",
];

export const migrationRemovePrivateUrl = () => {
  try {
    setTimeout(() => {
      setupHrefClick(document?.querySelectorAll("[onclick][sectionid]"));
      setupHrefClick(document?.querySelectorAll("[onclickprivate][sectionid]"));
      setupHref(document.querySelectorAll("a"));
      setupHref(document.querySelectorAll("div[href]"));
      removeEmptyHrefTag();
      addRoleAttribute();
      addCustonVisiters(document.querySelectorAll("a.assetseditor[href]"));
      AddTargetForTinymc();
      let elementOpen = document?.querySelectorAll(
        'a[onclick="window.open(this.href);"]'
      );
      elementOpen?.forEach((element) => {
        element.removeAttribute("onclick");
      });
      onLoadDivTagMigrationATagForDownload(
        document?.querySelectorAll("div[href][target='_blank']")
      );
    }, 1000);
  } catch (error) {}
};

const removeEmptyHrefTag = () => {
  const array = document.querySelectorAll('a[target="_blank"][href="#"]');
  array?.forEach((element) => {
    element?.removeAttribute("href");
  });
};

const onLoadDivTagMigrationATagForDownload = (elements) => {
  elements?.forEach((div) => {
    const a = document?.createElement("a");
    for (const attr of div.attributes) a.setAttribute(attr.name, attr.value);
    while (div.firstChild) a.appendChild(div.firstChild);
    div.parentNode.replaceChild(a, div);
  });
};

const AddTargetForTinymc = () => {
  document?.querySelectorAll("a[href]")?.forEach((element) => {
    if (
      element?.getAttribute("data-mce-href") &&
      !element?.getAttribute("target")
    )
      element.setAttribute("target", "_blank");
  });
};

const addCustonVisiters = (array) => {
  if (customVisitorCheckers?.includes(getTenantOrgId())) {
    let historyUrls = getCustomVisit();
    array?.forEach((element) => {
      if (historyUrls?.find((f) => f === element?.getAttribute("href"))) {
        element.classList.add("customVisited");
      }
    });
  }
};

const setupHrefClick = (array) => {
  array?.forEach((element) => {
    let attr =
      element.getAttribute("onclickprivate") || element.getAttribute("onclick");
    if (
      s3AssetUrls.some((url) => attr?.includes(url)) &&
      element?.getAttribute("sectionid")
    ) {
      element.setAttribute("href", attr);
      element.removeAttribute("onclickprivate");
      element.removeAttribute("onclick");
    }
  });
};

const setupHref = (elements) => {
  let data = JSON.parse(
    localStorage.getItem(process.env.REACT_APP_DASHBOARD_SESSION_AUTH_KEY)
  );
  //alert(JSON.stringify(data?.downloadToken));
  elements?.forEach((element) => {
    let attr =
      element?.getAttribute("href") ||
      element?.getAttribute("hrefprivate") ||
      element?.getAttribute("onclickprivate");
    element?.getAttribute("onclick");
    if (
      s3AssetUrls.some((url) => attr?.includes(url)) &&
      !attr?.startsWith(process.env.REACT_APP_DASHBOARD_API_URL)
    ) {
      if (attr.toLowerCase().includes(".pdf")) {
        if (!element?.getAttribute("target"))
          element.setAttribute("target", "_blank");
        const extractedStr = extractUrlFromRandamString(attr);
        element.setAttribute(
          "href",
          process.env.REACT_APP_DASHBOARD_API_URL +
            "/assetsservice/v1/api/asset/public/download/url/presigned?url=" +
            extractedStr +
            "&inline=true&redirect=true&downloadToken=" +
            data?.downloadToken
        );
      } else {
        if (!element?.getAttribute("target"))
          element.setAttribute("target", "_blank");
        const extractedStr = extractUrlFromRandamString(attr);
        element.setAttribute(
          "href",
          process.env.REACT_APP_DASHBOARD_API_URL +
            "/assetsservice/v1/api/asset/public/download/url/presigned?url=" +
            extractedStr +
            "&redirect=true&downloadToken=" +
            data?.downloadToken
        );
      }
      element.removeAttribute("hrefprivate");
      element.removeAttribute("onclickprivate");
      element.removeAttribute("onclick");
      element.style.cursor = "pointer";
    }
  });
};

const extractUrlFromRandamString = (attr) => {
  let splittedArray = attr.split("'");
  if (splittedArray?.length > 1) {
    for (let index = 0; index < splittedArray?.length; index++) {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      if (!!pattern.test(splittedArray[index])) {
        return splittedArray[index];
      }
    }
    return attr;
  } else {
    return attr;
  }
};

const addRoleAttribute = () => {
  try {
    document?.querySelectorAll("[onclickprivate]")?.forEach((ele) => {
      ele.setAttribute("role", "button");
    });
  } catch (error) {}
};

// If Is private url then get its signed url
export const checkPrivateUrl = (event) => {};

export const selectFundInit = () => {
  document.addEventListener("click", (e) => {
    const carouselListFunds =
      e?.target
        ?.closest(".report-carousel-indicators")
        ?.querySelector(".carousel-list-funds") ||
      e?.target
        ?.closest(".go-to-fund-wrapper")
        ?.querySelector(".carousel-list-funds");

    if (
      carouselListFunds &&
      carouselListFunds.querySelector("[onClick]") &&
      e.target.closest("[onClick]") !== null
    ) {
      let elindicators = carouselListFunds?.querySelector(
        ".carousel-indicators"
      );
      if (carouselListFunds?.classList?.contains("hide")) {
        carouselListFunds.classList.remove("hide");
        if (elindicators) elindicators.style.display = "block";
      } else {
        carouselListFunds?.classList?.add("hide");
        if (elindicators) elindicators.style.display = "none";
      }
    }
  });
};

const openFundList = (e) => {
  const carouselListFunds =
    e?.target
      ?.closest(".report-carousel-indicators")
      ?.querySelector(".carousel-list-funds") ||
    e?.target
      ?.closest(".go-to-fund-wrapper")
      ?.querySelector(".carousel-list-funds");
  const classCheck = e?.target?.closest(".btn_select_fund");
  if (
    carouselListFunds &&
    carouselListFunds.querySelector("[onClick]") &&
    e.target.closest("[onClick]") !== null
  ) {
    let elindicators = carouselListFunds?.querySelector(".carousel-indicators");
    if (carouselListFunds?.classList?.contains("hide")) {
      carouselListFunds.classList.remove("hide");
      if (elindicators) elindicators.style.display = "block";
    } else {
      carouselListFunds?.classList?.add("hide");
      if (elindicators) elindicators.style.display = "none";
    }
  }
};

function handleScroll() {
  // Reset body overflow to "auto" when the user scrolls
  document.body.style.overflow = "auto";
}
window.addEventListener("scroll", handleScroll);

export const adaLib = (bottomRef, callbackForFundWrapper, menu) => {
  try {
    if (menu === "contact") {
      document.querySelector(".skip-to-content-link")?.remove();
    }
    if (removeDropDownClass.includes(getTenantOrgId())) {
      adaLibMenu();
    }
    jumpToContent(bottomRef);

    if (adaTenants.includes(getTenantOrgId())) {
      if (
        getTenantOrgId() === "17" ||
        getTenantOrgId() === "36" ||
        getTenantOrgId() === "69" ||
        getTenantOrgId() === "1"
      ) {
        if (
          document
            .querySelector("#IdHeader > div")
            .classList.contains("REBRAND_2024")
        ) {
          callADA();
        }
      } else {
        callADA();
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const handleSlickForChop = (width) => {
  try {
    if (Number(getTenantOrgId()) === 7) {
      document
        .querySelectorAll(".photo-gallery-parent .slick-slide")
        ?.forEach((element) => {
          const currentWidth = width
            ? parseInt(width)
            : parseInt(element.style.width);
          const newWidth = currentWidth - 38;
          element.style.width = `${newWidth}px`;
        });
    }
  } catch (error) {}
};

const mobileAccordianClickHandler = (e) => {
  $(".custom-show-hide-panel-btn ").fadeOut("slow");
  var className = $(
    "#" + e?.target?.closest(".slideSecClass").id + " .collapsed"
  ).attr("class");
  $(".seperate-slider-collapsable.mobile-view-only").removeClass(
    "mobile-accordian"
  );
  if (changePlusMinusIconPOC.includes(getTenantOrgId())) {
    $(".slider-collapsing-icon svg path").attr(
      "d",
      "M0 9V7h7V0h2v7h7v2H9v7H7V9z"
    );
  }
  if (className.indexOf("custom-collapse") == -1) {
    $(".carousel-inner").hide();
    $(".slideSecClass .collapsed").attr("class", "collapsed");
    $(".carousel-indicators").fadeOut("fast");

    if (changePlusMinusIconPOC.includes(getTenantOrgId())) {
      $(
        "#" +
          e?.target?.closest(".slideSecClass").id +
          " .slider-collapsing-icon svg path"
      ).attr("d", "M0 2V0h16v2z");
    }

    if (!hideSelectFundInMobile.includes(getTenantOrgId())) {
      $(
        ".carousel-indicators-replica,.carousel-control,.fund-color-1 .right-arrow,.fund-color-1 .left-arrow"
      ).attr("style", "display:none");
    }

    $(".go-to-fund-wrapper.carousel-indicators-replica").attr(
      "style",
      "display:block"
    );

    $("#" + e?.target?.closest(".slideSecClass").id + " .collapsed").attr(
      "class",
      "collapsed custom-collapse"
    );
    // Css property use only for Dickinson in mobile accordian pane;
    $(
      "#" +
        e?.target?.closest(".slideSecClass").id +
        " .seperate-slider-collapsable.mobile-view-only"
    ).addClass("mobile-accordian");

    $(
      "#" + e?.target?.closest(".slideSecClass").id + " .carousel-inner"
    ).slideDown(100);

    /*Report- Show left/right Nav Arrow and Find x of X div Checker*/
    if ($(e?.target).hasClass("report-slider-collapsable-common")) {
      $(
        "#" +
          e?.target?.closest(".slideSecClass").id +
          " .carousel-indicators-replica"
      ).fadeIn("fast");
      $(
        "#" + e?.target?.closest(".slideSecClass").id + " .carousel-control"
      ).attr("style", "block");
      $("#" + e?.target?.closest(".slideSecClass").id + " .right-arrow").attr(
        "style",
        "display:block"
      );
      $("#" + e?.target?.closest(".slideSecClass").id + " .left-arrow").attr(
        "style",
        "display:block"
      );
    } else {
      $(
        "#" +
          e?.target?.closest(".slideSecClass").id +
          " .carousel-indicators-replica"
      ).fadeIn("fast");
      $(
        "#" + e?.target?.closest(".slideSecClass").id + " .carousel-indicators"
      ).fadeIn("fast");
      $(
        "#" +
          e?.target?.closest(".slideSecClass").id +
          " .custom-show-hide-panel-btn "
      ).fadeIn("fast");
    }
    /*Report- Reset carousel-indicators-replica's,carousel-control's if any Opend before Open to Another*/
    $(
      ".carousel-control,.fund-color-1 .right-arrow,.fund-color-1 .left-arrow"
    ).attr("style", "display:none");
    $(
      ".standard-template .carousel-control, .standard-template .right-arrow, .standard-template .left-arrow"
    ).attr("style", "display:inherit");
    $(
      "#MAIN-TEMPLATE .carousel-control, #MAIN-TEMPLATE .right-arrow, #MAIN-TEMPLATE .left-arrow"
    ).attr("style", "display:inherit");
    $(
      ".report-cp-panel .carousel-control, .report-cp-panel .right-arrow, .report-cp-panel .left-arrow"
    ).attr("style", "display:inherit");
    var targetOffsetr = "";
    if (Number(getTenantOrgId()) === 41) {
      targetOffsetr =
        document.querySelector("#" + e?.target?.closest(".slideSecClass").id)
          .offsetTop - document.querySelector("#IdHeader").clientHeight;
    } else if (Number(getTenantOrgId()) === 48) {
      if (window.location.href.includes("sites")) {
        targetOffsetr =
          document.querySelector("#" + e?.target?.closest(".slideSecClass").id)
            .offsetTop -
          document.querySelector("#IdHeader").clientHeight +
          1;
      } else {
        targetOffsetr =
          document.querySelector("#" + e?.target?.closest(".slideSecClass").id)
            .offsetTop -
          document.querySelector("#IdHeader").clientHeight +
          102;
      }
    } else if (Number(getTenantOrgId()) === 42) {
      let elementId = document.querySelector(
        "#" + e?.target?.closest(".slideSecClass").id
      );
      let headerHeight = document.querySelector("#IdHeader").clientHeight;
      if (document?.querySelector("body.bdy-centura")) {
        targetOffsetr = elementId.offsetTop - headerHeight - 30;
      } else if (document?.querySelector("body.bdy-cms")) {
        targetOffsetr = elementId.offsetTop - headerHeight - 7;
      } else if (document?.querySelector("body.bdy-barrow")) {
        targetOffsetr = elementId.offsetTop - headerHeight - 24;
      } else if (document?.querySelector("body.bdy-virginia-mason")) {
        targetOffsetr = elementId.offsetTop - headerHeight - 15;
      } else {
        targetOffsetr = elementId.offsetTop - headerHeight - 24;
      }
    } else if (Number(getTenantOrgId()) === 65) {
      if (document.querySelector("body.REBRAND_2023_BODY")) {
        if (window.location.pathname.includes("reports")) {
          if (e?.target?.closest(".slideSecClass").id === "slides_1") {
            targetOffsetr =
              $("#" + e?.target?.closest(".slideSecClass").id).offset().top +
              764;
          } else {
            targetOffsetr = $(
              "#" + e?.target?.closest(".slideSecClass").id
            ).offset().top;
          }
        } else {
          if (e?.target?.closest(".slideSecClass").id === "slides_1") {
            targetOffsetr =
              $("#" + e?.target?.closest(".slideSecClass").id).offset().top +
              650;
          } else {
            targetOffsetr = $(
              "#" + e?.target?.closest(".slideSecClass").id
            ).offset().top;
          }
        }
      }
    } else if (Number(getTenantOrgId()) === 72) {
      targetOffsetr = document.querySelector(
        "#" + e?.target?.closest(".slideSecClass").id
      ).offsetTop;
    } else if (
      Number(getTenantOrgId()) === 88 ||
      Number(getTenantOrgId()) === 85 ||
      Number(getTenantOrgId()) === 90 ||
      Number(getTenantOrgId()) === 98
    ) {
      targetOffsetr = document.querySelector(
        "#" + e?.target?.closest(".slideSecClass").id
      ).offsetTop;
    } else {
      targetOffsetr =
        $("#" + e?.target?.closest(".slideSecClass").id).offset().top -
        $("#" + "IdHeader").outerHeight();
    }
    if (
      mobileAccordionScrollTopZero.includes(getTenantOrgId()) ||
      (Number(getTenantOrgId()) === 7 && document?.querySelector(".berger"))
    ) {
      targetOffsetr =
        $("#" + e?.target?.closest(".slideSecClass").id).offset().top - 0;
    }
    if (Number(getTenantOrgId()) === 27) {
      $("html,body").animate(
        {
          scrollTop: targetOffsetr,
        },
        0
      );
    } else {
      $("html,body").animate(
        {
          scrollTop: targetOffsetr,
        },
        300
      );
    }
  } else {
    $("#" + e?.target?.closest(".slideSecClass").id + " .collapsed").attr(
      "class",
      "collapsed"
    );
    $(
      "#" +
        e?.target?.closest(".slideSecClass").id +
        " .seperate-slider-collapsable.mobile-view-only"
    ).removeClass("mobile-accordian");
    $(
      "#" + e?.target?.closest(".slideSecClass").id + " .carousel-inner"
    ).slideUp(200);
    if (changePlusMinusIconPOC.includes(getTenantOrgId())) {
      $(".slider-collapsing-icon svg path").attr(
        "d",
        "M0 9V7h7V0h2v7h7v2H9v7H7V9z"
      );
    }
    if ($(e?.target).hasClass("report-slider-collapsable-common")) {
      $(
        "#" +
          e?.target?.closest(".slideSecClass").id +
          " .carousel-indicators-replica"
      ).fadeOut("fast");
      $(
        "#" + e?.target?.closest(".slideSecClass").id + " .carousel-control"
      ).attr("style", "none");
      $("#" + e?.target?.closest(".slideSecClass").id + " .right-arrow").attr(
        "style",
        "display:none"
      );
      $("#" + e?.target?.closest(".slideSecClass").id + " .left-arrow").attr(
        "style",
        "display:none"
      );
    } else {
      $(
        "#" + e?.target?.closest(".slideSecClass").id + " .carousel-indicators"
      ).fadeOut("fast");
      $(
        "#" +
          e?.target?.closest(".slideSecClass").id +
          " .custom-show-hide-panel-btn "
      ).fadeOut("fast");
    }
  }
  removeStdLastItemSlide();
  carouselMigration();
};

export const identifierSection = (e) => {
  if (e.target.nodeType === 3) return;
  const sectionPanelId = e?.target
    ?.closest(".slideSecClass")
    ?.getAttribute("id");
  const ele = document?.getElementById("renderHtml");
  sectionPanelId && ele?.setAttribute("slideSecClass-id", sectionPanelId);
};

export const convertAnchorTagToTargetBlank = () => {
  try {
    setTimeout(() => {
      document.querySelectorAll("#LINK")?.forEach((ele) => {
        ele
          .querySelectorAll("a")
          .forEach((tag) => tag.setAttribute("target", "_blank"));
      });
    }, 2000);
  } catch (error) {}
};

export const removeNoScriptJs = () => {
  try {
    document.querySelector("noscript")?.remove();
  } catch (error) {}
};

export const allAttributeOnSlickArrow = () => {
  try {
    setTimeout(() => {
      document?.querySelectorAll(".slick-next")?.forEach((slickItem) => {
        slickItem?.setAttribute("aria-label", "Right arrow");
      });
      document?.querySelectorAll(".slick-prev")?.forEach((slickItem) => {
        slickItem?.setAttribute("aria-label", "Left arrow");
      });
    }, 1000);
  } catch (error) {}
};

export const clearLocalStorageOnLoginScreen = (endTime) => {
  try {
    var startTime = new Date();
    var endTime = new Date(endTime);
    var difference = endTime.getTime() - startTime.getTime();
    var resultInMinutes = Math.round(difference / 60000);

    if (resultInMinutes >= 60) {
      localStorage.clear();
      sessionStorage.clear();
      return true;
    }
    return false;
  } catch (error) {}
};

export const fetchAsset = (payload) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Connection", "Keep-Alive");
  myHeaders.append(
    "Authorization",
    JSON.parse(localStorage.getItem("tenant_user")).token
  );

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(
    `${process.env.REACT_APP_DASHBOARD_API_URL}/assetsservice/v1/api/asset/download/url/presigned?url=${payload}&inline=true&redirect=true`,
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {})
    .catch((error) => {
      throw error;
    });
};

// This method is used to replace content with MORE INFORMATION if content is blank
export const mobileDefaultHeader = () => {
  if (
    mobileDefaultHeaderRetroactiveChange?.includes(Number(getTenantOrgId()))
  ) {
    const panelHeaders = document?.querySelectorAll("div[onblur^='copytext']");
    panelHeaders?.forEach((header) => {
      if (header?.innerText?.trim() === "") {
        const slideSecClass = header?.closest(".slideSecClass");
        const mobileDiv = slideSecClass?.querySelector(
          ".slider-mobile-view-hearder"
        );
        mobileDiv.innerHTML = "MORE INFORMATION";
      }
    });
  }
};
